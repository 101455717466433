import Immutable from "immutable";

export default Immutable.Record({
  title: "",
  book_code: "",
  standard_test: true,
  formula_description: "",
  details: {},
  exercise_groups: Immutable.List(),
});
