import React from "react";

function FormError({ errors, fieldName }) {
  if (!errors || !Array.isArray(errors[fieldName]) || errors[fieldName].length === 0) {
    return null;
  }

  return (
    <>
      {errors[fieldName].map((error, i) => (
        <small key={i} className="form-text text-error">
          {error}
        </small>
      ))}
    </>
  );
}

export default FormError;
