import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import ExerciseActions from "../../actions/exercise-actions";
import EvaluationActions from "../../actions/evaluation-actions";
import ExerciseUtils from "../../utils/exercise-utils";
import SessionUtils from "../../utils/session-utils";

class Exercise extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showComment: false,
    };

    this.toggleComment = this.toggleComment.bind(this);
  }

  toggleComment() {
    if (SessionUtils.graded()) {
      this.setState((prevState) => ({
        showComment: !prevState.showComment,
      }));
    }
  }

  current() {
    return this.props.store.currentExerciseId !== null && this.props.exercise.get("id") == this.props.store.currentExerciseId;
  }

  setAsCurrentAndViewed() {
    if (!this.current()) {
      ExerciseActions.setCurrent(this.props.exercise);
    }

    if (this.notViewed() && !SessionUtils.closedOrGraded()) {
      ExerciseActions.view(this.sessionExercise());
    }

    if (SessionUtils.evaluationMode()) {
      EvaluationActions.setEvaluating(this.sessionExercise());
    }
  }

  sessionExercise() {
    return ExerciseUtils.getSessionExerciseFromExercise(this.props.exercise.get("id"));
  }

  marked() {
    return this.sessionExercise().get("marked");
  }

  notViewed() {
    return this.sessionExercise().get("not_viewed");
  }

  answered() {
    return this.sessionExercise().get("answered");
  }

  istr() {
    return this.props.exercise.get("typology") == "istr";
  }
  start() {
    return this.props.exercise.get("typology") == "start";
  }
  exe() {
    return this.props.exercise.get("typology") == "exe";
  }

  cssClass() {
    return ClassNames("session-exercise", {
      current: this.current(),
      "not-viewed": this.notViewed() && !SessionUtils.evaluationMode(),
      committed: SessionUtils.resultMode(),
      compact: this.props.compact,
    });
  }

  position() {
    let icon;

    if (!this.props.store.data.hasScoreRule && (SessionUtils.resultMode() || SessionUtils.evaluationMode())) {
      let iconCssClass = "fas fa-circle session-exercise-result-icon";

      if (SessionUtils.evaluationMode()) {
        iconCssClass = ClassNames(iconCssClass, {
          "text-success": ExerciseUtils.sessionExerciseStatus(this.sessionExercise()) === "success",
          "text-danger": ExerciseUtils.sessionExerciseStatus(this.sessionExercise()) === "failure",
        });
      } else {
        iconCssClass = ClassNames(iconCssClass, {
          "text-success": this.sessionExercise().get("success") === true,
          "text-danger": this.sessionExercise().get("success") === false,
        });
      }

      icon = <i className={iconCssClass} />;
    }

    return (
      <div className="session-exercise-position">
        {icon}
        {this.props.exercise.get("position")}
      </div>
    );
  }

  icon() {
    if (SessionUtils.resultMode()) return;

    let iconCssClass = ClassNames({
      "fas fa-clipboard": this.istr(),
      "fas fa-clipboard-check": this.start(),
      "fas fa-flag": this.exe() && this.marked(),
      "far fa-circle": this.exe() && !this.marked() && !this.answered(),
      "far fa-dot-circle": this.exe() && !this.marked() && this.answered(),
    });

    return (
      <div className="session-exercise-icon">
        <i className={iconCssClass} />
      </div>
    );
  }

  action() {
    let action;
    if (this.props.compact) {
      // nothing to render
    } else if (SessionUtils.resultMode() && this.hasComment()) {
      action = <i className="fas fa-comment-alt exercise-action" onClick={this.toggleComment} />;
    } else if (SessionUtils.evaluationMode() && this.toEvaluate()) {
      action = <i className="fas fa-pen exercise-action" />;
    }

    return action;
  }

  hasComment() {
    return this.sessionExercise().get("teacher_comment") !== undefined && this.sessionExercise().get("teacher_comment") !== null && this.sessionExercise().get("teacher_comment") !== "";
  }

  toEvaluate() {
    return this.sessionExercise().get("typology") == "exe" && this.props.exercise.get("open_items") !== 0;
  }

  comment() {
    if (!this.state.showComment || !SessionUtils.resultMode() || !this.hasComment()) return;

    return (
      <React.Fragment>
        <div className="w-100"></div>
        <div className="session-exercise-comment">{this.sessionExercise().get("teacher_comment")}</div>
      </React.Fragment>
    );
  }

  title() {
    if (!this.props.compact) {
      return <div className="session-exercise-title">{this.props.exercise.get("title")}</div>;
    }
  }

  render() {
    const store = this.props.store;
    const assignment = store.session.assignment;
    var conditionalPosition = this.position();

    // we hide position if there's a custom order and only for students
    if (assignment.shuffle_exercises && !SessionUtils.evaluationMode()) {
      conditionalPosition = "";
    }

    return (
      <div className={this.cssClass()} onClick={this.setAsCurrentAndViewed.bind(this)}>
        <div>
          {conditionalPosition}
          {this.icon()}
          {this.title()}
          {this.action()}
        </div>
        {this.comment()}
      </div>
    );
  }
}

Exercise.propTypes = {
  store: PropTypes.object.isRequired,
  exercise: PropTypes.object.isRequired,
  compact: PropTypes.bool,
};

Exercise.defaultProps = {
  compact: false,
};

export default Exercise;
