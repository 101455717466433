import React from "react";
import ScoreProgress from "../elements/score-progress";
import ResultModal from "../modals/result-modal";
import i18n from "../../utils/i18n";
import SessionUtils from "../../utils/session-utils";
import LayoutActions from "../../actions/layout-actions";

class Result extends React.PureComponent {
  detail() {
    let detail = "";
    let icon = <i className="fas fa-circle" />;

    if (SessionUtils.evaluationMode()) {
      // if (this.props.store.counters.exercisesNotEvaluated === 0) return;

      icon = <i className="fas fa-pen" />;
      detail += this.props.store.counters.exercisesNotEvaluated;
      detail += "/";
      detail += this.props.store.counters.exercisesCount;
      detail += " ";
      detail += i18n.t("sidebar.result.needEvaluation");
    } else {
      detail += this.props.store.session.score;
      detail += "/";
      detail += this.props.store.session.max_score;
      detail += " ";
      detail += i18n.t("sidebar.result.maxScore");
    }

    return (
      <div className="sidebar-result-detail">
        {icon}
        {detail}
      </div>
    );
  }

  moreDetails() {
    if (SessionUtils.evaluationMode()) {
      return (
        <React.Fragment>
          <h6 onClick={LayoutActions.toggleResultDetails}>{i18n.t("sidebar.result.showResultDetails")}</h6>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h6 onClick={LayoutActions.toggleResultModal}>{i18n.t("sidebar.result.showResultModal")}</h6>
          <ResultModal store={this.props.store} />
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <div className="sidebar-result">
        <div className="sidebar-result-progress">
          <div className="sidebar-result-progress-wrapper">
            <ScoreProgress store={this.props.store} width={60} />
          </div>
          <div className="sidebar-result-info">
            <h4>{i18n.t("sidebar.result.title")}</h4>
            {this.moreDetails()}
          </div>
        </div>
        {this.detail()}
      </div>
    );
  }
}

export default Result;
