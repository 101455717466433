import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import Exercise from "./exercise";
import ExerciseGroupActions from "../../actions/exercise-group-actions";
import SessionUtils from "../../utils/session-utils";

class ExerciseGroup extends React.PureComponent {
  alwaysExpanded() {
    // groups are always expanded in owner mode, while evaluating
    return SessionUtils.evaluationMode();
  }

  active() {
    if (this.alwaysExpanded()) {
      return true;
    } else {
      return this.props.exerciseGroup.get("id") == this.props.store.currentExerciseGroupId;
    }
  }

  setAsActive() {
    if (this.alwaysExpanded()) return;

    if (this.active()) {
      ExerciseGroupActions.setActiveGroup(null);
    } else {
      ExerciseGroupActions.setActiveGroup(this.props.exerciseGroup.get("id"));
    }
  }

  render() {
    let tabContentClass = ClassNames("sidebar-collapse-content", {
      "d-none": !this.active(),
    });

    let iconClass = ClassNames("fas expanded-icon", {
      "fa-chevron-down": this.active(),
      "fa-chevron-right": !this.active(),
      "d-none": this.alwaysExpanded(),
    });

    let headerClass = ClassNames("sidebar-collapse-header", {
      "always-expanded": this.alwaysExpanded(),
    });

    let exercises = this.props.exerciseGroup.get("exercises").map((exercise) => <Exercise key={exercise.get("id")} store={this.props.store} exercise={exercise} compact={this.props.compact} />);

    let header;
    let title;
    let icon;
    if (!this.props.compact) {
      title = this.props.exerciseGroup.get("title");
      icon = <i className={iconClass} />;
    }

    if (this.props.store.verification.exercise_groups.size > 1) {
      header = (
        <div className={headerClass} onClick={this.setAsActive.bind(this)}>
          <i className="fas fa-bars mr-4" />
          <span>{title}</span>
          {icon}
        </div>
      );
    }

    return (
      <div className="sidebar-collapse">
        {header}
        <div className={tabContentClass}>{exercises}</div>
      </div>
    );
  }
}

ExerciseGroup.propTypes = {
  store: PropTypes.object.isRequired,
  exerciseGroup: PropTypes.object.isRequired,
  compact: PropTypes.bool,
};

ExerciseGroup.defaultProps = {
  compact: false,
};

export default ExerciseGroup;
