import Immutable from "immutable";
import VerificationRecord from "./verification-record.js";
import SessionRecord from "./session-record.js";
import CountersRecord from "./counters-record.js";
import DataRecord from "./data-record.js";
import SessionUtils from "../utils/session-utils";

export default Immutable.Record({
  dataLoaded: false,
  sessionLoaded: false,
  verificationLoaded: false,
  reloading: false,
  showSidebar: !SessionUtils.mobileLayout(),
  showGradeModal: false,
  showCommitModal: false,
  answerSent: false,
  showResultModal: false,
  showResultDetails: false,
  showTimeoutModal: false,
  currentExerciseId: null,
  currentExerciseGroupId: null,
  activeExercisesTab: "allExercises",
  evaluatingSessionExerciseId: null,
  socket: null,
  socketConnected: false,
  completionPercentage: 0,
  elapsedSeconds: 0,
  data: new DataRecord(),
  counters: new CountersRecord(),
  session: new SessionRecord(),
  verification: new VerificationRecord(),
});
