import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import i18n from "../../utils/i18n";
import LayoutActions from "../../actions/layout-actions";
import EvaluationActions from "../../actions/evaluation-actions";

class GradeModal extends React.PureComponent {
  render() {
    return (
      <Modal isOpen={this.props.store.showGradeModal} toggle={LayoutActions.toggleGradeModal} size="md" className="session-base-modal">
        <ModalHeader toggle={LayoutActions.toggleGradeModal}>{i18n.t("modals.grade.title")}</ModalHeader>
        <ModalBody>{i18n.t("modals.grade.body")}</ModalBody>
        <ModalFooter>
          <div className="btn btn-lg btn-outline-secondary mr-auto" onClick={LayoutActions.toggleGradeModal}>
            {i18n.t("modals.cancel")}
          </div>
          <div className="btn btn-lg btn-primary" onClick={EvaluationActions.grade}>
            {i18n.t("modals.grade.submit")}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default GradeModal;
