import Immutable from "immutable";

export default Immutable.Record({
  sessionId: "",
  verificationId: "",
  userId: "",
  apiToken: "",
  socketHost: "",
  socketSignature: "",
  i18n: {},
  urls: {},
  assets: {},
  title: "",
  category: "",
  mode: "",
  hasScoreRule: true,
  defaultPdfArchiveFilename: null,
  allowExerciseShuffle: false,
  allowPdfExport: false,
  userRoles: [],
  rawTitle: null,
  isLegacyScoreMode: false,
});
