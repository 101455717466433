import "flatpickr/dist/flatpickr";
import { Italian } from "flatpickr/dist/l10n/it";

const testTimePicker = function () {
  let wrapper = $(".time-picker");

  if (wrapper.length > 0) {
    $(wrapper).flatpickr({
      dateFormat: "H:i",
      enableTime: true,
      noCalendar: true,
      allowInput: false,
      time_24hr: true,
      defaultHour: 1,
      defaultMinute: 0,
      minuteIncrement: 10,
      locale: Italian,
    });
  }
};

const datePicker = function () {
  let wrapper = $(".date-picker");

  if (wrapper.length > 0) {
    $(wrapper).flatpickr({
      dateFormat: "d-m-Y",
      position: "below",
      locale: Italian,
    });
  }
};

const reportDatePicker = function () {
  let wrapper = $(".report-date-picker");

  if (wrapper.length > 0) {
    $(wrapper).flatpickr({
      dateFormat: "d-m-Y",
      position: "below",
      locale: Italian,
      maxDate: new Date(),
      onChange() {
        $(wrapper).closest("form").submit();
      },
    });
  }
};

export default function () {
  testTimePicker();
  datePicker();
  reportDatePicker();
}
