import Utils from "./utils";

class SessionUtils extends Utils {
  static mobileLayout() {
    return window.matchMedia("(max-width: 767.98px)").matches;
  }

  // modes

  static previewMode() {
    return this.store().data.mode === "preview";
  }

  static autoMode() {
    return this.store().data.mode === "auto";
  }

  static ownerMode() {
    return this.store().data.mode === "owner";
  }

  static studentMode() {
    return this.store().data.mode === "student";
  }

  // cross modes

  static playMode() {
    return this.started() && !this.ownerMode();
  }

  static resultMode() {
    return this.graded() || (this.closed() && !this.studentMode());
  }

  static evaluationMode() {
    return this.closed() && (this.ownerMode() || this.previewMode());
  }

  // categories

  static simulation() {
    return this.store().data.category === "simulation";
  }

  static official() {
    return this.store().data.category === "official";
  }

  static simulationOrOfficial() {
    return this.simulation() || this.official();
  }

  static training() {
    return this.store().data.category === "training";
  }

  static easyTraining() {
    return this.store().data.category === "easy_training";
  }

  static trainingOrEasyTraining() {
    return this.training() || this.easyTraining();
  }

  // state

  static notOpened() {
    return this.store().session.state === "not_opened";
  }

  static opened() {
    return this.store().session.state === "opened";
  }

  static notOpenedOrOpened() {
    return this.notOpened() || this.opened();
  }

  static started() {
    return this.store().session.state === "started";
  }

  static closed() {
    return this.store().session.state === "closed";
  }

  static graded() {
    return this.store().session.state === "graded";
  }

  static closedOrGraded() {
    return this.closed() || this.graded();
  }
}

export default SessionUtils;
