import React from "react";
import i18n from "../../utils/i18n";
import Urls from "../../utils/urls";
import Socket from "../../utils/socket";
import SessionUtils from "../../utils/session-utils";
import ExerciseUtils from "../../utils/exercise-utils";
import LayoutActions from "../../actions/layout-actions";
import Timer from "./timer";
import Progress from "./progress";

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleSidebar = LayoutActions.toggleSidebar.bind(this);
  }

  reloading() {
    if (this.props.store.reloading) {
      return (
        <div className="col-auto ml-auto loading">
          <i className="fas fa-circle-notch fa-spin" />
        </div>
      );
    }
  }

  socketStatus() {
    if (SessionUtils.playMode() && !Socket.connected()) {
      return (
        <div className="col-auto">
          <div className="session-status">{i18n.t("socketDisconnected")}</div>
        </div>
      );
    }
  }

  currentExerciseName() {
    let currentExercise = ExerciseUtils.getCurrentExercise();
    if (!!currentExercise) {
      return currentExercise.get("title");
    }
  }

  mobileMenuTriggerIcon() {
    if (this.props.store.showSidebar) {
      return <i className="fas fa-arrow-left" />;
    } else {
      return <i className="fas fa-bars" />;
    }
  }

  getPrintButton = () => {
    const store = this.props.store;
    const data = store.data;
    const student = data.userRoles.includes("student");

    if (!data.allowPdfExport || student) {
      return null;
    }

    return (
      <div className="col-auto mobile-hide">
        <a href="#" className="print" data-toggle="modal" data-target="#printModal">
          <i className="fas fa-print" /> {i18n.t("header.print")}
        </a>
      </div>
    );
  };

  render() {
    return (
      <div className="session-header container-fluid">
        <div className="row">
          <div className="col-auto mobile-hide">
            <a className="back" href={Urls.get("backUrl")}>
              <i className="fas fa-arrow-left" />
              {i18n.t("header.back")}
            </a>
          </div>
          <div className="col-auto mobile-hide">
            <img src={this.props.store.data.assets.get("logo")} className="logo" />
          </div>
          <div className="col mobile-hide main-title">{this.props.store.data.title}</div>

          <div className="col-auto mobile-show">
            <div className="mobile-menu-trigger" onClick={this.toggleSidebar}>
              {this.mobileMenuTriggerIcon()}
            </div>
          </div>
          <div className="col mobile-show main-title">{this.currentExerciseName()}</div>

          {this.getPrintButton()}

          {this.reloading()}
          {this.socketStatus()}
          <Timer store={this.props.store} />
          <Progress store={this.props.store} />

          <div className="col-auto mobile-show">
            <a className="mobile-back" href={Urls.get("backUrl")}>
              <i className="fas fa-times" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
