import React from "react";
import ClassNames from "classnames";
import i18n from "../../utils/i18n";
import ExerciseActions from "../../actions/exercise-actions";
import ExerciseUtils from "../../utils/exercise-utils";
import SessionUtils from "../../utils/session-utils";

class MarkButton extends React.PureComponent {
  toggleMark() {
    if (!this.disabled()) {
      ExerciseActions.toggleMark(ExerciseUtils.getCurrentSessionExercise());
    }
  }

  disabled() {
    return ExerciseUtils.getCurrentSessionExercise() == null || this.props.store.reloading || ExerciseUtils.getCurrentExercise().get("typology") !== "exe";
  }

  hidden() {
    return SessionUtils.closedOrGraded() || SessionUtils.ownerMode();
  }

  marked() {
    if (this.disabled()) {
      return false;
    } else {
      return ExerciseUtils.getCurrentSessionExercise().get("marked");
    }
  }

  render() {
    if (this.hidden()) {
      return null;
    } else {
      let buttonCssClass = ClassNames("button button-left", {
        disabled: this.disabled(),
      });

      let iconCssClass = ClassNames("fas mr-3", {
        "fa-flag": !this.props.store.reloading,
        "fa-circle-notch fa-spin": this.props.store.reloading,
      });

      let label = this.marked() ? i18n.t("footer.unmark") : i18n.t("footer.mark");

      return (
        <div className="col-auto">
          <div className={buttonCssClass} onClick={this.toggleMark.bind(this)}>
            <i className={iconCssClass} />
            <span className="mobile-hide">{label}</span>
          </div>
        </div>
      );
    }
  }
}

export default MarkButton;
