import React from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";

class CategorySelect extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectContentOpened: false,
      selectedOptionName: props.selectedOptionName,
    };

    this.toggleSelectContent = this.toggleSelectContent.bind(this);
  }

  handleClickOutside(event) {
    if (!this.state.selectContentOpened) return;
    this.toggleSelectContent();
  }

  getPlaceholder() {
    // default fallback for placeholder
    let placeholder = this.props.i18n.placeholder;

    if (this.state.selectedOptionName !== "") {
      placeholder = this.state.selectedOptionName;
    }
    return placeholder;
  }

  label() {
    return (
      <label className="form-control-label required d-block" onClick={this.toggleSelectContent}>
        {this.props.i18n.label}
        <abbr> * </abbr>
      </label>
    );
  }

  categoryChoice() {
    if (this.props.mobile) {
      return this.radios();
    } else {
      return this.select();
    }
  }

  radios() {
    return <div className="category-radios">{this.props.options.map((category) => this.categoryRadioContent(category))}</div>;
  }

  categoryRadioContent(category) {
    let cssClass = "category-radio";
    let iconCssClass = "selection-icon far";
    if (this.isCategorySelected(category.key)) {
      cssClass += " selected";
      iconCssClass += " fa-dot-circle";
    } else {
      iconCssClass += " fa-circle";
    }

    return (
      <div className={cssClass} onClick={this.handleCategorySelect.bind(this, category)} key={category.key}>
        <img src={category.iconPath} />
        <span>{category.value}</span>
        <i className={iconCssClass} />
      </div>
    );
  }

  select() {
    let icon = this.state.selectContentOpened ? <i className="fas fa-caret-up" /> : <i className="fas fa-caret-down" />;

    return (
      <div className="custom-select category-select">
        <div className="form-control custom-select-field" onClick={this.toggleSelectContent}>
          {this.getPlaceholder()}
          {icon}
        </div>
        {this.selectContent()}
      </div>
    );
  }

  selectContent() {
    if (this.state.selectContentOpened) {
      return <div className="custom-select-content">{this.props.options.map((category) => this.categoryContent(category))}</div>;
    }
  }

  categoryContent(category) {
    let cssClass = "custom-select-item category-entry";
    if (this.isCategorySelected(category.key)) {
      cssClass += " selected";
    }

    return (
      <div className={cssClass} onClick={this.handleCategorySelect.bind(this, category)} key={category.key}>
        <img src={category.iconPath} className="category-entry__image" />

        <div>
          <strong>{category.value}</strong>
          <div className="category-entry__description">{category.description}</div>
        </div>
      </div>
    );
  }

  isCategorySelected(categoryKey) {
    return this.props.selectedOption == categoryKey;
  }

  hiddenField() {
    return (
      <div className="hidden-fields hidden">
        <input type="hidden" name={this.props.fieldName} value={this.props.selectedOption} />
      </div>
    );
  }

  toggleSelectContent() {
    this.setState((prevState) => ({
      selectContentOpened: !prevState.selectContentOpened,
    }));
  }

  handleCategorySelect(category) {
    this.props.onChange(category.key);
    this.setState({
      selectedOptionName: category.value,
      selectContentOpened: false,
    });
  }

  render() {
    return (
      <div>
        {this.label()}
        {this.categoryChoice()}
        {this.hiddenField()}
      </div>
    );
  }
}

CategorySelect.propTypes = {
  i18n: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.string,
  selectedOptionName: PropTypes.string,
  mobile: PropTypes.bool.isRequired,
};

export default onClickOutside(CategorySelect);
