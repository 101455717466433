import Store from "../store";

// base utils class
// static methods will be available to all utils subclasses

class Utils {
  static store() {
    return Store.getState();
  }
}

export default Utils;
