import React from "react";
import Moment from "moment";
import i18n from "../../utils/i18n";
import TimeoutModal from "../modals/timeout-modal";
import SessionActions from "../../actions/session-actions";
import SocketActions from "../../actions/socket-actions";
import SessionUtils from "../../utils/session-utils";

class Timer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      timerIntervalId: null,
      timeout: false,
      timeoutCommitted: false,
    };
  }

  componentDidMount() {
    if (SessionUtils.playMode()) {
      this.startTimer();
      setTimeout(() => {
        SocketActions.init();
      }, 0);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // timer interval handling
    if (this.state.timerIntervalId !== null && (SessionUtils.closedOrGraded() || this.state.timeout)) {
      clearInterval(this.state.timerIntervalId);
    } else if (this.state.timerIntervalId == null && SessionUtils.playMode()) {
      this.startTimer();
    }

    if (SessionUtils.playMode() && this.props.store.socket == null) {
      setTimeout(() => {
        SocketActions.init();
      }, 1);
    }

    if (SessionUtils.playMode() && SessionUtils.simulationOrOfficial() && !this.infiniteTestTime()) {
      if (!this.state.timeout && !this.state.timeoutCommitted && this.props.store.elapsedSeconds > this.testTime()) {
        this.setState({
          timeout: true,
        });
      }

      if (this.state.timeout && !this.state.timeoutCommitted) {
        this.setState({
          timeoutCommitted: true,
        });

        setTimeout(() => {
          SessionActions.commit("timeout");
        }, 1);
      }
    }
  }

  startTimer() {
    const interval = 1000;

    let timerIntervalId = setInterval(function () {
      SessionActions.incrementTimer(interval);
    }, interval);

    this.setState({
      timerIntervalId: timerIntervalId,
    });
  }

  shouldHideTimer() {
    // don't show timer before session has started
    return SessionUtils.notOpenedOrOpened();
  }

  timer() {
    if (this.shouldHideTimer()) {
      return null;
    } else {
      let seconds;

      if (SessionUtils.started() && SessionUtils.simulationOrOfficial() && !this.infiniteTestTime()) {
        // show missing time
        seconds = this.testTime() - this.props.store.elapsedSeconds;
      } else {
        // show elapsed time
        seconds = this.props.store.elapsedSeconds;
      }

      if (seconds < 0) {
        return i18n.t("header.timeExpired");
      } else {
        return this.formatSeconds(seconds);
      }
    }
  }

  maxTime() {
    if (SessionUtils.started() && SessionUtils.simulationOrOfficial() && !this.infiniteTestTime()) {
      // don't show max test time for started simulation/official tests
      return null;
    } else {
      let maxTime;
      let separator = <span className="ml-1 mr-1">/</span>;

      if (this.infiniteTestTime()) {
        maxTime = <span className="infinite-test-time">{i18n.t("header.infiniteTestTime")}</span>;
      } else {
        maxTime = this.formatSeconds(this.testTime());
      }

      if (this.shouldHideTimer()) {
        separator = null;
      }

      return (
        <div className="d-none d-sm-none d-md-inline-block">
          {separator}
          {maxTime}
        </div>
      );
    }
  }

  testTime() {
    return this.props.store.session.assignment.test_time;
  }

  infiniteTestTime() {
    return this.testTime() === null || this.testTime() === undefined || this.testTime() === 0;
  }

  formatSeconds(seconds) {
    return Moment.utc(seconds * 1000).format("H:mm:ss");
  }

  render() {
    return (
      <div className="col-auto ml-auto">
        <div className="timer">
          <i className="fas fa-stopwatch" />
          {this.timer()}
          {this.maxTime()}
        </div>
        <TimeoutModal store={this.props.store} />
      </div>
    );
  }
}

export default Timer;
