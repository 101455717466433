import React from "react";
import ClassNames from "classnames";
import i18n from "../../utils/i18n";
import ExerciseActions from "../../actions/exercise-actions";
import ExerciseUtils from "../../utils/exercise-utils";

class PrevButton extends React.PureComponent {
  prev() {
    if (!this.disabled()) {
      ExerciseActions.prev();
    }
  }

  disabled() {
    return ExerciseUtils.getCurrentSessionExercise() == null || ExerciseUtils.isFirstExercise();
  }

  render() {
    let buttonCssClass = ClassNames("button button-right", {
      disabled: this.disabled(),
    });

    return (
      <div className="col-auto ml-auto">
        <div className={buttonCssClass} onClick={this.prev.bind(this)}>
          <i className="fas fa-chevron-left mr-3" />
          <span className="mobile-hide">{i18n.t("footer.prev")}</span>
        </div>
      </div>
    );
  }
}

export default PrevButton;
