import React from "react";
import ClassNames from "classnames";
import i18n from "../../utils/i18n";
import ExerciseActions from "../../actions/exercise-actions";
import ExerciseUtils from "../../utils/exercise-utils";

class NextButton extends React.PureComponent {
  next() {
    if (!this.disabled()) {
      ExerciseActions.next();
    }
  }

  disabled() {
    return ExerciseUtils.getCurrentSessionExercise() == null || ExerciseUtils.isLastExercise();
  }

  render() {
    let buttonCssClass = ClassNames("button button-right", {
      disabled: this.disabled(),
    });

    return (
      <div className="col-auto">
        <div className={buttonCssClass} onClick={this.next.bind(this)}>
          <span className="mobile-hide">{i18n.t("footer.next")}</span>
          <i className="fas fa-chevron-right ml-3" />
        </div>
      </div>
    );
  }
}

export default NextButton;
