import Actions from "../constants/actions";
import Dispatcher from "../dispatcher/dispatcher";

const SessionActions = {
  loadSession(sessionId) {
    Dispatcher.dispatch({
      type: Actions.LOAD_SESSION,
      sessionId,
    });
  },
  setSession(sessionData, answerSent = false) {
    Dispatcher.dispatch({
      type: Actions.SET_SESSION,
      sessionData,
      answerSent,
    });
  },
  incrementTimer(interval) {
    Dispatcher.dispatch({
      type: Actions.INCREMENT_TIMER,
      interval,
    });
  },
  commit(reason) {
    Dispatcher.dispatch({
      type: Actions.COMMIT,
      reason,
    });
  },
  commitCallback(data) {
    Dispatcher.dispatch({
      type: Actions.COMMIT_CALLBACK,
      data,
    });
  },
};

export default SessionActions;
