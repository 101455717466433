import React from "react";
import ClassNames from "classnames";
import i18n from "../../utils/i18n";

class ResultDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  header() {
    let iconCssClass = ClassNames("fas", {
      "fa-minus": this.state.expanded,
      "fa-plus": !this.state.expanded,
    });

    return (
      <div className="score-rules-header" onClick={this.toggleExpanded}>
        {i18n.t("resultDetails.scoreRulesHeader")}
        <i className={iconCssClass} />
      </div>
    );
  }

  content() {
    if (!this.state.expanded) return;

    let description;
    description = <div className="score-rules-description">{i18n.t("resultDetails.formulaDescription")}</div>;

    return (
      <div className="score-rules-table mt-4">
        {this.table()}
        {description}
      </div>
    );
  }

  table() {
    if (this.props.store.verification.details === undefined || this.props.store.verification.details === null || this.props.store.verification.details.get("testScoreRules").size <= 0) {
      return;
    }

    let rules = this.props.store.verification.details.get("testScoreRules");
    let rows = [];
    rules.forEach((rule, index) => {
      rows.push(
        <tr key={"group-" + index} className="group-row">
          <td>
            {i18n.t("resultDetails.scoreRulesGroup")}
            {rule.get("group")}
          </td>
          <td>{i18n.t("resultDetails.scoreRulesValue")}</td>
        </tr>
      );

      rule.get("ranges").forEach((range, internalIndex) => {
        let min = range.get("min");
        let max = range.get("max");
        let answers = max;
        if (min !== max) {
          answers = min + " - " + max;
        }

        rows.push(
          <tr key={"group-" + index + "-range-" + internalIndex}>
            <td>
              <span>{answers}</span>
              {i18n.t("resultDetails.scoreRulesTableAnswers")}
            </td>
            <td>{range.get("formula")}</td>
          </tr>
        );
      });
    });

    return (
      <table className="table table-sm table-bordered">
        <thead className="thead-dark">
          <tr>
            <th colSpan="2">{i18n.t("resultDetails.scoreRulesTableHeader")}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  render() {
    return (
      <div className="score-rules">
        {this.header()}
        {this.content()}
      </div>
    );
  }
}

export default ResultDetails;
