import Actions from "../constants/actions";

function setActiveGroup(state, action) {
  return state.set("currentExerciseGroupId", action.exerciseGroupId);
}

export default function reduce(state, action) {
  switch (action.type) {
    case Actions.SET_ACTIVE_GROUP:
      return setActiveGroup(state, action);

    default:
      return state;
  }
}
