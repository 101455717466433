import React from "react";
import { Container } from "flux/utils";
import ReactDOM from "react-dom";
import Store from "../store";
import Main from "../components/main";

function AppView(props) {
  return <Main {...props} />;
}

class CounterContainer extends React.Component {
  static getStores() {
    return [Store];
  }

  static calculateState(prevState, props) {
    return {
      store: Store.getState(),
      data: props,
      // TBD: pass actions as props or import inside component? Example:
      // actions: {
      //   setCurrent: ExerciseActions.setCurrent
      // }
    };
  }

  render() {
    return <AppView {...this.state} />;
  }
}

export default Container.create(CounterContainer, { pure: true, withProps: true });
