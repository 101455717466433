const Actions = {
  // boot
  SET_DATA: "SET_DATA",
  LOAD_VERIFICATION: "LOAD_VERIFICATION",
  SET_VERIFICATION: "SET_VERIFICATION",

  // session
  LOAD_SESSION: "LOAD_SESSION",
  SET_SESSION: "SET_SESSION",
  INCREMENT_TIMER: "INCREMENT_TIMER",
  COMMIT: "COMMIT",
  COMMIT_CALLBACK: "COMMIT_CALLBACK",

  // exercise
  SET_CURRENT: "SET_CURRENT",
  VIEW: "VIEW",
  TOGGLE_MARK: "TOGGLE_MARK",
  ANSWER: "ANSWER",
  NEXT: "NEXT",
  PREV: "PREV",

  // evaluation
  SET_EVALUATING: "SET_EVALUATING",
  COMMENT: "COMMENT",
  UPDATE_SCORE: "UPDATE_SCORE",
  UPDATE_SESSION: "UPDATE_SESSION",
  OVERRIDE_SESSION_SCORE: "OVERRIDE_SESSION_SCORE",
  GRADE: "GRADE",

  // exercise group
  SET_ACTIVE_GROUP: "SET_ACTIVE_GROUP",

  // socket
  INIT: "INIT",
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
  PHASE_ELAPSED_SECONDS: "PHASE_ELAPSED_SECONDS",

  // layout
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  TOGGLE_GRADE_MODAL: "TOGGLE_GRADE_MODAL",
  TOGGLE_TIMEOUT_MODAL: "TOGGLE_TIMEOUT_MODAL",
  TOGGLE_COMMIT_MODAL: "TOGGLE_COMMIT_MODAL",
  TOGGLE_RESULT_MODAL: "TOGGLE_RESULT_MODAL",
  TOGGLE_RESULT_DETAILS: "TOGGLE_RESULT_DETAILS",
  SET_ACTIVE_EXERCISES_TAB: "SET_ACTIVE_EXERCISES_TAB",
};

export default Actions;
