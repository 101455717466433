import { Sortable } from "@shopify/draggable";
import Axios from "axios";

const logger = console;

class V2TestSorter {
  constructor({ containerSelector = document.querySelector("tbody"), itemSelector = "tr", handleSelector = ".handle" } = {}) {
    this.container = containerSelector;
    this.sortable = new Sortable(this.container, {
      draggable: itemSelector,
      handle: handleSelector,
    });
    this.sortable.on("sortable:stop", this.handleSortableStopEvent.bind(this));
  }

  handleSortableStopEvent(sortableStopEvent) {
    let data = {};

    const items = this.sortable.getDraggableElementsForContainer(sortableStopEvent.newContainer);

    items.forEach((item, index) => {
      data[item.dataset.draggableModelName] = data[item.dataset.draggableModelName] || [];
      data[item.dataset.draggableModelName].push({
        id: item.dataset.draggableRecordId,
        sort_index: index,
      });
    });

    Axios.post(this.getSortUrl(), data, {
      headers: { "X-CSRF-Token": this.getCSRFToken() },
    })
      .then(logger.debug)
      .catch(logger.error);

    // TODO: rollback unless successful
  }

  getSortUrl() {
    return this.container.dataset.draggableSortUrl;
  }

  getCSRFToken() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }
}

export const initV2TestSorter = () => {
  if (document.querySelector("[data-draggable-sort-url]")) {
    document.querySelectorAll("[data-draggable-sort-url]").forEach(function (selector) {
      new V2TestSorter({
        containerSelector: selector,
      });
    });
  }
};

export default V2TestSorter;
