import React from "react";
import ClassNames from "classnames";
import ExerciseGroup from "./exercise-group";
import ExerciseEvaluation from "./exercise-evaluation";
import SessionUtils from "../../utils/session-utils";

class Exercises extends React.PureComponent {
  evaluating() {
    return this.props.store.evaluatingSessionExerciseId !== null && SessionUtils.evaluationMode();
  }

  exerciseEvaluation() {
    if (this.evaluating()) {
      return <ExerciseEvaluation store={this.props.store} />;
    }
  }

  exerciseGroups() {
    let exerciseGroups = this.props.store.verification.exercise_groups.map((exerciseGroup) => (
      <ExerciseGroup key={exerciseGroup.get("id")} store={this.props.store} exerciseGroup={exerciseGroup} compact={this.evaluating()} />
    ));

    let cssClass = ClassNames("exercise-groups", {
      compact: this.evaluating(),
    });

    return <div className={cssClass}>{exerciseGroups}</div>;
  }

  render() {
    return (
      <div className="sidebar-tab-content d-flex">
        {this.exerciseGroups()}
        {this.exerciseEvaluation()}
      </div>
    );
  }
}

export default Exercises;
