import React from "react";

class Loading extends React.PureComponent {
  render() {
    return (
      <div className="session-loading">
        <h3>{this.props.loadingLabel}</h3>
        <i className="fas fa-circle-notch fa-spin" />
      </div>
    );
  }
}

export default Loading;
