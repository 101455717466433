import React from "react";
import { Progress } from "react-sweet-progress";
import SessionUtils from "../../utils/session-utils";

class ScoreProgress extends React.PureComponent {
  status() {
    if (this.props.store.session.success) {
      return "success";
    } else {
      return "error";
    }
  }

  theme() {
    let content;
    if (SessionUtils.evaluationMode()) {
      content = this.props.store.session.score;
      content += "/";
      content += this.props.store.session.max_score;
    } else {
      content = this.props.store.session.score_base_10;
    }

    return {
      error: {
        symbol: content,
        trailColor: "#edccd1",
        color: "#fb0007",
      },
      success: {
        symbol: content,
        trailColor: "#bdf3d2",
        color: "#1bcc46",
      },
    };
  }

  percentage() {
    if (this.props.store.session.score_percentage == 0) {
      // progress circle doesn't display at all with 0%
      return 0.1;
    } else {
      return this.props.store.session.score_percentage;
    }
  }

  render() {
    return <Progress type="circle" width={this.props.width} strokeWidth={10} percent={this.percentage()} status={this.status()} theme={this.theme()} />;
  }
}

export default ScoreProgress;
