import Dispatcher from "./dispatcher/dispatcher";
import { ReduceStore } from "flux/utils";
import InitialState from "./records/initial-state";
import BootReducer from "./reducers/boot-reducer";
import SessionReducer from "./reducers/session-reducer";
import ExerciseReducer from "./reducers/exercise-reducer";
import EvaluationReducer from "./reducers/evaluation-reducer";
import ExerciseGroupReducer from "./reducers/exercise-group-reducer";
import SocketReducer from "./reducers/socket-reducer";
import LayoutReducer from "./reducers/layout-reducer";

const reducers = [BootReducer, SessionReducer, ExerciseReducer, EvaluationReducer, ExerciseGroupReducer, SocketReducer, LayoutReducer];

class SessionStore extends ReduceStore {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return new InitialState();
  }

  reduce(state, action) {
    let updated = state;

    if (action.type === undefined) {
      throw new Error(`undefined action! ${JSON.stringify(action)}`);
    }

    for (const reducer of reducers) {
      updated = reducer(updated, action);
    }

    return updated;
  }
}

export default new SessionStore();
