import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Moment from "moment";
import ScoreProgress from "../elements/score-progress";
import ScoreRules from "../elements/score-rules";
import i18n from "../../utils/i18n";
import Urls from "../../utils/urls";
import LayoutActions from "../../actions/layout-actions";
import SessionUtils from "../../utils/session-utils";

class ResultModal extends React.PureComponent {
  modalBody() {
    if (this.props.store.session.success) {
      return i18n.t("modals.result.bodySuccess") + " " + this.props.store.session.score_base_10;
    } else {
      return i18n.t("modals.result.bodyFailure") + " " + this.props.store.session.score_base_10;
    }
  }

  time() {
    let time = Moment.utc(this.props.store.elapsedSeconds * 1000).format("H:mm:ss");
    time += " ";
    time += i18n.t("modals.result.elapsedTimeSeparator");
    time += " ";
    time += Moment.utc(this.props.store.session.assignment.test_time * 1000).format("H:mm:ss");

    return time;
  }

  values() {
    if (!this.props.store.data.hasScoreRule) {
      return (
        <div className="result-modal-values">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="row">
                <div className="col-4">
                  <span>{i18n.t("modals.result.correct")}</span>
                  <div className="result-modal-value">
                    <i className="fas fa-circle text-success" />
                    {this.props.store.counters.exercisesCorrect}/{this.props.store.counters.exercisesCount}
                  </div>
                </div>
                <div className="col-4">
                  <span>{i18n.t("modals.result.incorrect")}</span>
                  <div className="result-modal-value">
                    <i className="fas fa-circle text-danger" />
                    {this.props.store.counters.exercisesIncorrect}/{this.props.store.counters.exercisesCount}
                  </div>
                </div>
                <div className="col-4">
                  <span>{i18n.t("modals.result.notEvaluated")}</span>
                  <div className="result-modal-value">
                    <i className="fas fa-circle" />
                    {this.props.store.counters.exercisesNotEvaluated}/{this.props.store.counters.exercisesCount}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <span>{i18n.t("modals.result.elapsedTime")}</span>
              <div className="result-modal-value">
                <i className="fas fa-stopwatch large-icon" />
                {this.time()}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="result-modal-values">
          <div className="row">
            <div className="col-12 col-md-5">
              <span>{i18n.t("modals.result.elapsedTime")}</span>
              <div className="result-modal-value">
                <i className="fas fa-stopwatch large-icon" />
                {this.time()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  averageIncluded() {
    if (!SessionUtils.graded()) return;

    if (this.props.store.session.no_average) {
      return (
        <b>
          <br />
          {i18n.t("resultDetails.averageNotIncluded")}
        </b>
      );
    } else {
      return (
        <b>
          <br />
          {i18n.t("resultDetails.averageIncluded")}
        </b>
      );
    }
  }

  comment() {
    let comment = this.props.store.session.teacher_comment;
    if (SessionUtils.graded() && comment !== undefined && comment !== null && comment !== "") {
      return (
        <div className="teacher-comment">
          <label>{i18n.t("modals.result.teacherComment")}</label>
          <p>"{comment}"</p>
        </div>
      );
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.store.showResultModal} toggle={LayoutActions.toggleResultModal} size="lg" className="session-base-modal result-modal">
        <ModalHeader toggle={LayoutActions.toggleResultModal}>{i18n.t("modals.result.title")}</ModalHeader>
        <ModalBody>
          <div className="result-modal-progress">
            <div className="result-modal-info">
              <p>
                {this.modalBody()}
                {this.averageIncluded()}
              </p>
            </div>
            <div className="result-modal-progress-wrapper">
              <ScoreProgress store={this.props.store} width={80} />
            </div>
          </div>
          {this.values()}
          {this.comment()}
          <ScoreRules store={this.props.store} />
        </ModalBody>
        <ModalFooter>
          <a className="btn btn-lg btn-outline-secondary mr-auto" href={Urls.get("backUrl")}>
            {i18n.t("modals.result.autoExit")}
          </a>
          <div className="btn btn-lg btn-primary" onClick={LayoutActions.toggleResultModal}>
            {i18n.t("modals.result.autoClose")}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ResultModal;
