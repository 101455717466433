import React, { useState } from "react";

import NewAssignmentForm from "./NewAssignmentForm";

function NewAssignmentModalBody(props) {
  const { i18n } = props;
  const [step, setStep] = useState(1);

  const backButtonProps =
    step === 1
      ? {
          "aria-dismiss": "modal",
        }
      : {};

  const handleSubmit = (e) => {
    if (step !== 2) {
      e.preventDefault();
      setStep((oldStep) => oldStep + 1);
    }
  };

  const handleBack = (e) => {
    if (step !== 1) {
      e.preventDefault();
      setStep((oldStep) => oldStep - 1);
    }
  };

  return (
    <>
      <div className="modal-body">
        <NewAssignmentForm {...props} step={step} />
      </div>

      <div className="modal-footer">
        <div className="btn btn-lg btn-outline-secondary mr-auto" {...backButtonProps} onClick={handleBack}>
          {step === 1 ? i18n.buttons.cancel : i18n.buttons.back}
        </div>

        <input type="submit" name="commit" value={step === 2 ? i18n.buttons.assign : i18n.buttons.continue} className="btn btn-primary btn btn-lg btn-primary" onClick={handleSubmit} />
      </div>
    </>
  );
}

export default NewAssignmentModalBody;
