import Actions from "../constants/actions";
import Dispatcher from "../dispatcher/dispatcher";

const ExerciseActions = {
  setCurrent(exercise) {
    Dispatcher.dispatch({
      type: Actions.SET_CURRENT,
      exercise,
    });
  },
  view(sessionExercise) {
    Dispatcher.dispatch({
      type: Actions.VIEW,
      sessionExercise,
    });
  },
  toggleMark(sessionExercise) {
    Dispatcher.dispatch({
      type: Actions.TOGGLE_MARK,
      sessionExercise,
    });
  },
  answer(answerData) {
    Dispatcher.dispatch({
      type: Actions.ANSWER,
      answerData,
    });
  },
  next() {
    Dispatcher.dispatch({
      type: Actions.NEXT,
    });
  },
  prev() {
    Dispatcher.dispatch({
      type: Actions.PREV,
    });
  },
};

export default ExerciseActions;
