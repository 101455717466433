import Actions from "../constants/actions";
import SessionUtils from "../utils/session-utils";
// import ExerciseActions from '../actions/exercise-actions';
// import ExerciseUtils from '../utils/exercise-utils';

function toggleSidebar(state) {
  return state.set("showSidebar", !state.showSidebar);
}

function toggleCommitModal(state) {
  if (state.showCommitModal) {
    // must only hide the modal
    return state.set("showCommitModal", false);
  } else {
    // must submit current exercise answer and than show the commit modal
    // returning empty currentExerciseId will run an empty exercise player, needed to run the onUnmount callback.

    // than, setting currentExerciseId with previous value will re-run the player, to not show a white screen
    // setTimeout(() => {
    //   ExerciseActions.setCurrent(ExerciseUtils.getExercise(state.currentExerciseId).get('id'));
    // }, 1);

    return state.merge({
      showCommitModal: !state.showCommitModal,
      answerSent: false,
      currentExerciseId: null,
    });
  }
}

function toggleGradeModal(state) {
  if (SessionUtils.evaluationMode()) {
    state = state.set("showGradeModal", !state.showGradeModal);
  }
  return state;
}

function toggleTimeoutModal(state) {
  if (SessionUtils.closedOrGraded()) {
    state = state.set("showTimeoutModal", !state.showTimeoutModal);
  }
  return state;
}

function toggleResultModal(state) {
  if (SessionUtils.resultMode()) {
    state = state.set("showResultModal", !state.showResultModal);
  }
  return state;
}

function toggleResultDetails(state) {
  if (SessionUtils.evaluationMode()) {
    state = state.set("showResultDetails", !state.showResultDetails);
  }
  return state;
}

function setActiveExercisesTab(state, action) {
  let exercisesTab = action.exercisesTab;
  if (exercisesTab === "" || exercisesTab === null || exercisesTab === undefined) {
    exercisesTab = "allExercises"; // default tab
  }

  return state.set("activeExercisesTab", exercisesTab);
}

export default function reduce(state, action) {
  switch (action.type) {
    case Actions.TOGGLE_SIDEBAR:
      return toggleSidebar(state, action);

    case Actions.TOGGLE_GRADE_MODAL:
      return toggleGradeModal(state, action);

    case Actions.TOGGLE_TIMEOUT_MODAL:
      return toggleTimeoutModal(state, action);

    case Actions.TOGGLE_COMMIT_MODAL:
      return toggleCommitModal(state, action);

    case Actions.TOGGLE_RESULT_MODAL:
      return toggleResultModal(state, action);

    case Actions.TOGGLE_RESULT_DETAILS:
      return toggleResultDetails(state, action);

    case Actions.SET_ACTIVE_EXERCISES_TAB:
      return setActiveExercisesTab(state, action);

    default:
      return state;
  }
}
