import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import i18n from "../../utils/i18n";
import LayoutActions from "../../actions/layout-actions";
import SessionActions from "../../actions/session-actions";

class CommitModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.commit = this.commit.bind(this);
  }

  commit() {
    SessionActions.commit("manual");
  }

  render() {
    let title, body, submit;
    let allAnswered = this.props.store.counters.exercisesAnswered == this.props.store.counters.exercisesCount;

    if (allAnswered) {
      title = i18n.t("modals.commit.commitTitle");
      body = i18n.t("modals.commit.commitBody");
      submit = i18n.t("modals.commit.submit");
    } else {
      body = i18n.t("modals.commit.incompleteBody");
      body = body.replace("%{not_answered}", this.props.store.counters.exercisesNotAnswered);
      body = body.replace("%{total}", this.props.store.counters.exercisesCount);
      body = body.replace("%{exercise}", this.props.store.counters.exercisesNotAnswered > 1 ? i18n.t("modals.commit.exercises") : i18n.t("modals.commit.exercise"));
      title = i18n.t("modals.commit.incompleteTitle");
      submit = i18n.t("modals.commit.submit");
    }

    // XXX Modifica funzionale: questo campo serve ad aspettare che l'esercizio
    // (che viene chiamato answer, occhio) venga postato sul server prima di
    // aprire la modale. E' un accrocchio non troppo bello ma per fare le cose
    // per bene andrebbe rifattorizzato quasi tutto il progetto.
    return (
      <Modal isOpen={this.props.store.showCommitModal && this.props.store.answerSent} toggle={LayoutActions.toggleCommitModal} size="md" className="session-base-modal">
        <ModalHeader toggle={LayoutActions.toggleCommitModal}>{title}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <div className="btn btn-lg btn-outline-secondary mr-auto" onClick={LayoutActions.toggleCommitModal}>
            {i18n.t("modals.cancel")}
          </div>
          <div className="btn btn-lg btn-primary" onClick={this.commit}>
            {submit}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CommitModal;
