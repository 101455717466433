import Actions from "../constants/actions";
import Dispatcher from "../dispatcher/dispatcher";

const EvaluationActions = {
  setEvaluating(sessionExercise) {
    Dispatcher.dispatch({
      type: Actions.SET_EVALUATING,
      sessionExercise,
    });
  },
  comment(sessionExercise, comment) {
    Dispatcher.dispatch({
      type: Actions.COMMENT,
      sessionExercise,
      comment,
    });
  },
  updateScore(sessionExercise, score) {
    Dispatcher.dispatch({
      type: Actions.UPDATE_SCORE,
      sessionExercise,
      score,
    });
  },
  updateSession(teacherComment, noAverage) {
    Dispatcher.dispatch({
      type: Actions.UPDATE_SESSION,
      teacherComment,
      noAverage,
    });
  },
  overrideSessionScore(score) {
    Dispatcher.dispatch({
      type: Actions.OVERRIDE_SESSION_SCORE,
      score,
    });
  },
  grade() {
    Dispatcher.dispatch({
      type: Actions.GRADE,
    });
  },
};

export default EvaluationActions;
