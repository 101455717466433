import React from "react";
import i18n from "../../utils/i18n";
import LayoutActions from "../../actions/layout-actions";

class SidebarButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleSidebar = LayoutActions.toggleSidebar.bind(this);
  }

  labelWithIcon() {
    if (this.props.store.showSidebar) {
      return (
        <div className="button button-left" onClick={this.toggleSidebar}>
          <i className="fas fa-chevron-left mr-3" />
          <span>{i18n.t("footer.hideSidebar")}</span>
        </div>
      );
    } else {
      return (
        <div className="button button-left" onClick={this.toggleSidebar}>
          <span>{i18n.t("footer.showSidebar")}</span>
          <i className="fas fa-chevron-right ml-3" />
        </div>
      );
    }
  }

  render() {
    return <div className="col-auto mobile-hide">{this.labelWithIcon()}</div>;
  }
}

export default SidebarButton;
