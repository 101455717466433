import utilities from "./utilities";
import modal from "./modal";

const openModal = function (modalContent) {
  modal.show(modalContent);
  utilities.btnLoadingCompleted();
};

const openVerificationExportPdfModal = function (modalContent) {
  modal.hide();
  modal.show(modalContent);
  utilities.btnLoadingCompleted();
  utilities.showWorkingButton();
  $('[data-toggle="tooltip"]').tooltip();
};

const handleFilters = function () {
  $(".filters-group-header").click(function () {
    let parent = $(this).parent();
    if (!$(parent).hasClass("always-opened")) {
      if ($(parent).hasClass("opened")) {
        $(parent).removeClass("opened");
      } else {
        $(parent).addClass("opened");
      }
    }
  });
};

export default {
  openModal: openModal,
  handleFilters: handleFilters,
  openVerificationExportPdfModal: openVerificationExportPdfModal,
};
