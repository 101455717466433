import "core-js/stable";
import "regenerator-runtime/runtime";

import "../src/application_bsmart.sass";

import $ from "jquery";
import "bootstrap";
import "ellipsis.js";

import Rails from "@rails/ujs";
Rails.start();

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import initBindings from "../modules/bindings";
import initPickers from "../modules/pickers";

import modal from "../modules/modal";
import groupFromClassroomModal from "../modules/group_from_classroom";
import uploader from "../modules/uploader";
import verifications from "../modules/verifications";
import assignments from "../modules/assignments";
import userAssignments from "../modules/user_assignments";
import utilities from "../modules/utilities";
import { initV2TestSorter } from "../modules/v2_test/sorter";
import Cookie from "../modules/cookie";

window.BSmart = {};

window.addEventListener("DOMContentLoaded", () => {
  window.BSmart.Cookie = new Cookie();
});

$(document).ready(function () {
  initBindings();
  initPickers();
  Ellipsis({
    lines: 1,
    debounce: 200,
  });

  modal.showIfPresent();
  uploader.pollRunningImports();
  verifications.handleFilters();

  // $(".best_in_place").best_in_place();
  initV2TestSorter();

  // HACK:
  // Add a css body class on input focus to detect virtual keyboard openings
  // on mobile devices.
  const textFocusingClass = "editablecontent-focusin";
  const textFocusingSelectors = 'input, textarea, [contenteditable="true"]';
  $(document).on("focus", textFocusingSelectors, function () {
    $(document.body).addClass(textFocusingClass);
  });
  $(document).on("blur", textFocusingSelectors, function () {
    $(document.body).removeClass(textFocusingClass);
  });

  $(document).on("click", "#new-assignment-modal .card-header button", function (e) {
    // This prevents accordion buttons to submit the form
    e.preventDefault();
  });

  $(document).on("show.bs.collapse", "#new-assignment-modal .card", function () {
    $(this).addClass("open");
  });

  $(document).on("hide.bs.collapse", "#new-assignment-modal .card", function () {
    $(this).removeClass("open");
  });

  $(document).on("hidden.bs.modal", "#destroyClassroomModal", function (e) {
    window.location.replace(e.target.getAttribute("redirect_url"));
  });
});

// remote actions called from rails js views
window.remoteActions = {
  openModal: modal.show,
  hideModal: modal.hide,
  openGroupFromClassroomModal: groupFromClassroomModal.show,
  reset: modal.reset,
  redirect: modal.redirect,
  refreshRunningImports: uploader.refreshRunningImports,
  openVerificationModal: verifications.openModal,
  toggleActionBtn: utilities.toggleActionBtn,
  openVerificationAssignSidebar: assignments.openVerificationAssignSidebar,
  substituteVerificationAssignSidebar: assignments.substituteModal,
  openAssignmentModal: assignments.openAssignmentModal,
  openAssignmentSessionModal: assignments.openAssignmentSessionModal,
  openUserAssignmentModal: userAssignments.openUserAssignmentModal,
  openVerificationExportPdfModal: verifications.openVerificationExportPdfModal,
  showWorkingButton: utilities.showWorkingButton,
};

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
