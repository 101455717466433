import React from "react";
import ClassNames from "classnames";
import ScoreProgress from "../elements/score-progress";
import ScoreRules from "../elements/score-rules";
import i18n from "../../utils/i18n";
import EvaluationActions from "../../actions/evaluation-actions";
import LayoutActions from "../../actions/layout-actions";

class ResultDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: "",
      score: "",
      commentExpanded: false,
      scoreExpanded: false,
    };

    this.focusScore = this.focusScore.bind(this);
    this.changeScore = this.changeScore.bind(this);
    this.submitScore = this.submitScore.bind(this);
    this.resetScore = this.resetScore.bind(this);

    this.focusComment = this.focusComment.bind(this);
    this.changeComment = this.changeComment.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.cancelComment = this.cancelComment.bind(this);

    this.averageCheckboxChange = this.averageCheckboxChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      comment: this.props.store.session.teacher_comment,
      score: this.props.store.session.score,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.store.session.score !== this.props.store.session.score) {
      this.setState({
        score: this.props.store.session.score,
      });
    }
  }

  // actions

  focusScore() {
    this.setState({
      scoreExpanded: true,
    });
  }

  changeScore(event) {
    let value = parseInt(event.target.value);
    if (event.target.value === "" || (value >= 0 && value <= this.props.store.session.max_score)) {
      this.setState({
        score: event.target.value,
      });
    }
  }

  submitScore(event) {
    event.preventDefault();

    if (!this.props.store.verification.standard_test) {
      EvaluationActions.overrideSessionScore(this.state.score);
      this.setState({
        scoreExpanded: false,
      });
    }
  }

  resetScore() {
    EvaluationActions.overrideSessionScore(null);
    this.setState({
      scoreExpanded: false,
    });
  }

  focusComment() {
    this.setState({
      commentExpanded: true,
    });
  }

  cancelComment() {
    this.setState({
      comment: this.props.store.session.teacher_comment,
      commentExpanded: false,
    });
  }

  changeComment(event) {
    this.setState({
      comment: event.target.value,
    });
  }

  submitComment(event) {
    event.preventDefault();
    EvaluationActions.updateSession(this.state.comment);
    this.setState({
      commentExpanded: false,
    });
  }

  averageCheckboxChange(event) {
    EvaluationActions.updateSession(null, !event.target.checked);
  }

  // content

  progressSubtitle() {
    if (this.props.store.session.success) {
      return <h5 className="text-success">{i18n.t("resultDetails.success")}</h5>;
    } else {
      return <h5 className="text-danger">{i18n.t("resultDetails.failure")}</h5>;
    }
  }

  progressTitle() {
    if (this.props.store.session.success) {
      return <h4 className="mb-3">{i18n.t("resultDetails.successBody")}</h4>;
    } else {
      return <h4 className="mb-3">{i18n.t("resultDetails.failureBody")}</h4>;
    }
  }

  progressBody() {
    let body1 = i18n.t("resultDetails.body1");
    body1 = body1.replace("%{raw_score}", this.props.store.session.raw_score);
    body1 = body1.replace("%{max_score}", this.props.store.session.max_score);

    let body2 = i18n.t("resultDetails.body2");
    body2 = body2.replace("%{total}", this.props.store.counters.exercisesCount);
    body2 = body2.replace("%{answered}", this.props.store.counters.exercisesCount - this.props.store.counters.exercisesNotEvaluated);

    return (
      <p className="mb-3">
        <b>{body1}</b>
        {body2}
      </p>
    );
  }

  score() {
    if (this.props.store.verification.standard_test) return;

    if (this.state.scoreExpanded) {
      return (
        <form className="score" onSubmit={this.submitScore}>
          <div className="form-group">
            <input type="text" className="form-control" value={this.state.score} onChange={this.changeScore} />
            <input type="submit" value={i18n.t("resultDetails.scoreSubmit")} className="btn btn-primary ml-3" />
          </div>
        </form>
      );
    } else {
      let resetAction;
      if (this.props.store.session.raw_score !== this.props.store.session.score) {
        resetAction = (
          <div className="reset-action ml-4" onClick={this.resetScore}>
            <i className="fas fa-undo mr-2" />
            {i18n.t("resultDetails.scoreReset")}
          </div>
        );
      }

      return (
        <React.Fragment>
          <div className="btn btn-primary" onClick={this.focusScore}>
            {i18n.t("resultDetails.scoreButton")}
          </div>
          {resetAction}
        </React.Fragment>
      );
    }
  }

  values() {
    if (!this.props.store.data.hasScoreRule) {
      return (
        <React.Fragment>
          <h4 className="mt-5 mb-3">{i18n.t("resultDetails.values")}</h4>
          <div className="row">
            <div className="col-auto">
              <div className="result-details-value">
                <i className="fas fa-circle text-success" />
                {this.props.store.counters.exercisesCorrect}/{this.props.store.counters.exercisesCount}
                <span>{i18n.t("modals.result.correct")}</span>
              </div>
            </div>
            <div className="col-auto">
              <div className="result-details-value">
                <i className="fas fa-circle text-danger" />
                {this.props.store.counters.exercisesIncorrect}/{this.props.store.counters.exercisesCount}
                <span>{i18n.t("modals.result.incorrect")}</span>
              </div>
            </div>
            <div className="col-auto">
              <div className="result-details-value">
                <i className="fas fa-circle" />
                {this.props.store.counters.exercisesNotEvaluated}/{this.props.store.counters.exercisesCount}
                <span>{i18n.t("modals.result.notEvaluated")}</span>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  comment() {
    let actions;
    if (this.state.commentExpanded) {
      actions = (
        <div className="d-flex">
          <div className="btn btn-outline-dark mr-auto mb-3" onClick={this.cancelComment}>
            {i18n.t("sidebar.evaluation.cancelComment")}
          </div>
          <input type="submit" value={i18n.t("sidebar.evaluation.confirmComment")} className="btn btn-primary mb-3" />
        </div>
      );
    }

    return (
      <form className="comment" onSubmit={this.submitComment}>
        <div className="form-group">
          <label className="mt-5" htmlFor="comment">
            {i18n.t("resultDetails.commentLabel")}
          </label>
          <textarea
            id="comment"
            rows={5}
            className="form-control"
            value={this.state.comment}
            placeholder={i18n.t("resultDetails.commentPlaceholder")}
            onChange={this.changeComment}
            onFocus={this.focusComment}
          />
        </div>
        {actions}
      </form>
    );
  }

  averageCheckbox() {
    return (
      <div className="form-check">
        <input type="checkbox" className="form-check-input" id="average-checkbox" checked={!this.props.store.session.no_average} onChange={this.averageCheckboxChange} />
        <label className="form-check-label" htmlFor="average-checkbox">
          {i18n.t("resultDetails.averageCheckbox")}
        </label>
      </div>
    );
  }

  render() {
    let cssClass = ClassNames("session-player", {
      "full-screen": !this.props.store.showSidebar,
    });

    return (
      <div className={cssClass}>
        <div className="container-fluid result-details-wrapper">
          <div className="result-details row">
            <div className="col-12 col-lg-8">
              <i className="fas fa-times close" onClick={LayoutActions.toggleResultDetails} />
              <h2 className="mb-5">{i18n.t("resultDetails.title")}</h2>
              <div className="row">
                <div className="col-auto result-details-progress-wrapper">
                  <ScoreProgress store={this.props.store} width={100} />
                  {this.progressSubtitle()}
                </div>
                <div className="col result-details-body">
                  {this.progressTitle()}
                  {this.progressBody()}
                  {this.score()}
                </div>
              </div>
              {this.values()}
              {this.comment()}
              {this.averageCheckbox()}
              <ScoreRules store={this.props.store} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResultDetails;
