import Immutable from "immutable";
import Axios from "axios";
import Actions from "../constants/actions";
import SessionActions from "../actions/session-actions";
import LayoutActions from "../actions/layout-actions";
import BootActions from "../actions/boot-actions";
import Urls from "../utils/urls";

import SessionRecord from "../records/session-record.js";
import AssignmentRecord from "../records/assignment-record.js";

function loadSession(state, action) {
  Axios.get(Urls.get("loadSessionUrl"), {
    headers: { Authorization: state.data.apiToken },
  })
    .then(function (response) {
      SessionActions.setSession(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

  return state;
}

function setSession(state, action) {
  let session = new SessionRecord(Immutable.fromJS(action.sessionData));
  session = session.set("assignment", new AssignmentRecord(Immutable.fromJS(action.sessionData.assignment)));

  // set counters
  let exercisesAnswered = session.session_exercises.count(function (sessionExercise) {
    return sessionExercise.get("answered") && sessionExercise.get("typology") == "exe";
  });
  let exercisesNotAnswered = session.session_exercises.count(function (sessionExercise) {
    return !sessionExercise.get("answered") && sessionExercise.get("typology") == "exe";
  });
  let exercisesMarked = session.session_exercises.count(function (sessionExercise) {
    return sessionExercise.get("marked") && sessionExercise.get("typology") == "exe";
  });
  let exercisesViewed = session.session_exercises.count(function (sessionExercise) {
    return !sessionExercise.get("not_viewed") && sessionExercise.get("typology") == "exe";
  });
  let exercisesCount = session.session_exercises.count(function (sessionExercise) {
    return sessionExercise.get("typology") == "exe";
  });
  let exercisesCorrect = session.session_exercises.count(function (sessionExercise) {
    return sessionExercise.get("typology") == "exe" && sessionExercise.get("success") === true;
  });
  let exercisesIncorrect = session.session_exercises.count(function (sessionExercise) {
    return sessionExercise.get("typology") == "exe" && sessionExercise.get("success") === false;
  });
  let exercisesNotEvaluated = session.session_exercises.count(function (sessionExercise) {
    return sessionExercise.get("typology") == "exe" && (sessionExercise.get("success") === undefined || sessionExercise.get("success") === null);
  });

  let counters = state.counters.merge({
    exercisesViewed: exercisesViewed,
    exercisesAnswered: exercisesAnswered,
    exercisesNotAnswered: exercisesNotAnswered,
    exercisesMarked: exercisesMarked,
    exercisesCount: exercisesCount,
    exercisesCorrect: exercisesCorrect,
    exercisesIncorrect: exercisesIncorrect,
    exercisesNotEvaluated: exercisesNotEvaluated,
  });

  let completionPercentage = 0;
  if (exercisesAnswered !== undefined && exercisesCount !== undefined) {
    completionPercentage = Math.round((exercisesAnswered * 100) / exercisesCount);
  }

  const answerSent = action.answerSent;

  return state.merge({
    session: session,
    sessionLoaded: true,
    reloading: false,
    elapsedSeconds: session.elapsed_seconds,
    completionPercentage: completionPercentage,
    counters: counters,
    answerSent,
  });
}

function incrementTimer(state, action) {
  let interval = action.interval / 1000;
  return state.set("elapsedSeconds", state.elapsedSeconds + interval);
}

function commit(state, action) {
  Axios.post(
    Urls.get("commitUrl"),
    {
      reason: action.reason,
    },
    {
      headers: { Authorization: state.data.apiToken },
    }
  )
    .then(function (response) {
      SessionActions.commitCallback(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

  return state.merge({
    reloading: true,
  });
}

function commitCallback(state, action) {
  if (action.data === undefined) return state;
  let callbackAction = action.data.callback_action;

  if (callbackAction === "show_result") {
    state = state.merge({
      reloading: false,
      showResultModal: true,
    });

    setTimeout(() => {
      SessionActions.setSession(action.data.session);
      BootActions.setVerification(action.data.verification);
      LayoutActions.setActiveExercisesTab();
    }, 1);
  } else if (callbackAction === "timeout") {
    state = state.merge({
      reloading: false,
      showTimeoutModal: true,
    });
  } else if (callbackAction === "back") {
    window.location.href = Urls.get("backUrl");
  }

  return state;
}

export default function reduce(state, action) {
  switch (action.type) {
    case Actions.LOAD_SESSION:
      return loadSession(state, action);

    case Actions.SET_SESSION:
      return setSession(state, action);

    case Actions.INCREMENT_TIMER:
      return incrementTimer(state, action);

    case Actions.COMMIT:
      return commit(state, action);

    case Actions.COMMIT_CALLBACK:
      return commitCallback(state, action);

    default:
      return state;
  }
}
