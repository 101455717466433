import React from "react";
import i18n from "../../utils/i18n";

class MobileProgress extends React.PureComponent {
  progress() {
    return this.completionPercentage();
  }

  progressCss() {
    return {
      width: this.completionPercentage(),
    };
  }

  completionPercentage() {
    return this.props.store.completionPercentage + "%";
  }

  render() {
    return (
      <div className="sidebar-session-progress">
        <h3>{i18n.t("sidebar.status")}</h3>
        <div className="row">
          <div className="col">
            <div className="progress">
              <div className="progress-bar" style={this.progressCss()} />
            </div>
          </div>
          <div className="col-auto">
            <span>{this.progress()}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileProgress;
