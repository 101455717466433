import React from "react";
import ClassNames from "classnames";
import i18n from "../../utils/i18n";
import CommitModal from "../modals/commit-modal";
import LayoutActions from "../../actions/layout-actions";
import SessionUtils from "../../utils/session-utils";
import Socket from "../../utils/socket";

class CommitButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.openCommitModal = this.openCommitModal.bind(this);
  }

  disabled() {
    return this.props.store.reloading || !SessionUtils.started() || !Socket.connected();
  }

  hidden() {
    return SessionUtils.closedOrGraded() || SessionUtils.ownerMode();
  }

  openCommitModal() {
    if (!this.disabled()) {
      LayoutActions.toggleCommitModal();
    }
  }

  render() {
    if (this.hidden()) {
      return null;
    } else {
      let buttonCssClass = ClassNames("button button-right no-border commit", {
        disabled: this.disabled(),
      });

      return (
        <div className="col-auto">
          <div className={buttonCssClass} onClick={this.openCommitModal}>
            {i18n.t("footer.commit")}
          </div>
          <CommitModal store={this.props.store} />
        </div>
      );
    }
  }
}

export default CommitButton;
