import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Urls from "../../utils/urls";
import i18n from "../../utils/i18n";

class TimeoutModal extends React.PureComponent {
  render() {
    return (
      <Modal isOpen={this.props.store.showTimeoutModal} size="md" className="session-base-modal">
        <ModalHeader>{i18n.t("modals.timeout.title")}</ModalHeader>
        <ModalBody>{i18n.t("modals.timeout.body")}</ModalBody>
        <ModalFooter>
          <a className="btn btn-lg btn-primary ml-auto" href={Urls.get("backUrl")}>
            {i18n.t("modals.timeout.exit")}
          </a>
        </ModalFooter>
      </Modal>
    );
  }
}

export default TimeoutModal;
