import React from "react";
import ClassNames from "classnames";
import Loading from "./elements/loading";
import Header from "./header/header";
import Sidebar from "./sidebar/sidebar";
import Player from "./elements/player";
import ResultDetails from "./elements/result-details";
import Footer from "./footer/footer";
import BootActions from "../actions/boot-actions";
import SessionActions from "../actions/session-actions";
import SessionUtils from "../utils/session-utils";
import PrintModal from "./header/print-modal";

class Main extends React.PureComponent {
  componentDidMount() {
    BootActions.setData(this.props.data);
    BootActions.loadVerification(this.props.data.verificationId);
    SessionActions.loadSession(this.props.data.sessionId);
  }

  loaded() {
    return this.props.store.get("dataLoaded") && this.props.store.get("sessionLoaded") && this.props.store.get("verificationLoaded");
  }

  sidebar() {
    if (this.props.store.showSidebar) {
      return <Sidebar store={this.props.store} />;
    }
  }

  mainContent() {
    if (this.props.store.showResultDetails) {
      return <ResultDetails store={this.props.store} />;
    } else {
      return <Player store={this.props.store} />;
    }
  }

  layoutContentCssClass() {
    return ClassNames("layout-content", {
      opaque: SessionUtils.mobileLayout() && this.props.store.showSidebar,
    });
  }

  render() {
    if (this.loaded()) {
      return (
        <React.Fragment>
          <div className="layout-header">
            <Header store={this.props.store} />
          </div>
          <div className={this.layoutContentCssClass()}>
            {this.sidebar()}
            {this.mainContent()}
            <PrintModal store={this.props.store} />
          </div>
          <div className="layout-footer">
            <Footer store={this.props.store} />
          </div>
        </React.Fragment>
      );
    } else {
      return <Loading loadingLabel={this.props.data.i18n.loading} />;
    }
  }
}

export default Main;
