import utilities from "./utilities";
import modal from "./modal";

const openUserAssignmentModal = function (modalContent) {
  modal.hide();
  modal.show(modalContent);
  utilities.btnLoadingCompleted();
};

export default {
  openUserAssignmentModal: openUserAssignmentModal,
};
