import Axios from "axios";
import Actions from "../constants/actions";
import SessionActions from "../actions/session-actions";
import Urls from "../utils/urls";
import SessionUtils from "../utils/session-utils";

function setEvaluating(state, action) {
  if (action.sessionExercise !== undefined && action.sessionExercise !== null && SessionUtils.evaluationMode()) {
    return state.set("evaluatingSessionExerciseId", action.sessionExercise.get("id"));
  } else {
    return state.set("evaluatingSessionExerciseId", null);
  }
}

function comment(state, action) {
  return evaluate(state, {
    id: action.sessionExercise.get("id"),
    teacherComment: action.comment,
  });
}

function updateScore(state, action) {
  return evaluate(state, {
    id: action.sessionExercise.get("id"),
    rawTeacherScores: action.score,
  });
}

function evaluate(state, payload) {
  if (SessionUtils.evaluationMode()) {
    Axios.put(Urls.get("evaluateUrl"), payload, {
      headers: { Authorization: state.data.apiToken },
    }).then(function (response) {
      SessionActions.setSession(response.data);
    });

    return state.merge({
      reloading: true,
    });
  } else {
    // session isn't in evaluation mode
    return state;
  }
}

function updateSession(state, action) {
  if (SessionUtils.evaluationMode()) {
    Axios.put(
      Urls.get("updateUrl"),
      {
        teacherComment: action.teacherComment,
        noAverage: action.noAverage,
      },
      {
        headers: { Authorization: state.data.apiToken },
      }
    ).then(function (response) {
      SessionActions.setSession(response.data);
    });

    return state.merge({
      reloading: true,
    });
  } else {
    return state;
  }
}

function overrideSessionScore(state, action) {
  if (SessionUtils.evaluationMode() && !state.verification.standard_test) {
    Axios.post(
      Urls.get("overrideScoreUrl"),
      {
        score: action.score,
      },
      {
        headers: { Authorization: state.data.apiToken },
      }
    ).then(function (response) {
      SessionActions.setSession(response.data);
    });

    return state.merge({
      reloading: true,
    });
  } else {
    return state;
  }
}

function grade(state) {
  if (SessionUtils.evaluationMode()) {
    Axios.post(
      Urls.get("gradeUrl"),
      {},
      {
        headers: { Authorization: state.data.apiToken },
      }
    ).then(function (response) {
      window.location.href = Urls.get("backUrl");
    });
  }
  return state;
}

export default function reduce(state, action) {
  switch (action.type) {
    case Actions.SET_EVALUATING:
      return setEvaluating(state, action);

    case Actions.COMMENT:
      return comment(state, action);

    case Actions.UPDATE_SCORE:
      return updateScore(state, action);

    case Actions.UPDATE_SESSION:
      return updateSession(state, action);

    case Actions.OVERRIDE_SESSION_SCORE:
      return overrideSessionScore(state, action);

    case Actions.GRADE:
      return grade(state, action);

    default:
      return state;
  }
}
