import Axios from "axios";
import Actions from "../constants/actions";
import SessionActions from "../actions/session-actions";
import ExerciseActions from "../actions/exercise-actions";
import Urls from "../utils/urls";
import ExerciseUtils from "../utils/exercise-utils";
import SessionUtils from "../utils/session-utils";

function setCurrent(state, action) {
  return state.set("currentExerciseId", action.exercise.get("id"));
}

function view(state, action) {
  if (!SessionUtils.closedOrGraded() && !SessionUtils.ownerMode() && action.sessionExercise !== null && action.sessionExercise !== undefined && action.sessionExercise.get("not_viewed")) {
    Axios.put(
      Urls.get("viewUrl"),
      {
        id: action.sessionExercise.get("id"),
      },
      {
        headers: { Authorization: state.data.apiToken },
      }
    )
      .then(function (response) {
        SessionActions.setSession(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    return state.merge({
      reloading: true,
    });
  } else {
    // session is closed or graded, cannot set as viewed
    return state;
  }
}

function toggleMark(state, action) {
  if (
    !SessionUtils.closedOrGraded() &&
    !SessionUtils.ownerMode() &&
    action.sessionExercise !== null &&
    action.sessionExercise !== undefined &&
    ExerciseUtils.getExerciseFromSessionExercise(action.sessionExercise).get("typology") == "exe"
  ) {
    Axios.put(
      Urls.get("toggleMarkedUrl"),
      {
        id: action.sessionExercise.get("id"),
      },
      {
        headers: { Authorization: state.data.apiToken },
      }
    )
      .then(function (response) {
        SessionActions.setSession(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    return state.merge({
      reloading: true,
    });
  } else {
    // instructions or start resource type, cannot mark
    return state;
  }
}

function answer(state, action) {
  if (!SessionUtils.closedOrGraded() && !SessionUtils.ownerMode()) {
    Axios.put(
      Urls.get("answerUrl"),
      {
        id: action.answerData.userDataId,
        answer_data: action.answerData,
      },
      {
        headers: { Authorization: state.data.apiToken },
      }
    )
      .then(function (response) {
        SessionActions.setSession(response.data, true);
      })
      .catch(function (error) {
        console.log(error);
      });

    return state.merge({
      reloading: true,
    });
  } else {
    // session is closed or graded, cannot answer
    return state;
  }
}

function next(state, action) {
  return prevOrNext(state, action, "next");
}

function prev(state, action) {
  return prevOrNext(state, action, "prev");
}

function prevOrNext(state, action, type) {
  let next = type == "next";
  let prev = !next;
  let canContinue = next ? !ExerciseUtils.isLastExercise() : !ExerciseUtils.isFirstExercise();

  let done = false;
  let newSessionExercise = null;
  let newExerciseId = null;
  let newExerciseGroupId = null;

  if (ExerciseUtils.getCurrentSessionExercise() !== null && canContinue) {
    state.verification.exercise_groups.forEach((exerciseGroup, exerciseGroupIndex) => {
      if (!done) {
        let currentExerciseIndex = exerciseGroup.get("exercises").findIndex((exercise) => {
          return exercise.get("id") == state.currentExerciseId;
        });
        done = true;

        if (currentExerciseIndex == -1) {
          // current exercise isn't in this group, proceed with next group
          done = false;
        } else if (next) {
          if (currentExerciseIndex == exerciseGroup.get("exercises").size - 1) {
            // current exercise is the last in this group, move to first exercise of next group
            let nextExerciseGroup = state.verification.exercise_groups.get(exerciseGroupIndex + 1);

            newExerciseId = nextExerciseGroup.get("exercises").first().get("id");
            newExerciseGroupId = nextExerciseGroup.get("id");
          } else {
            // current exercise is in this group, go to next
            let newExercise = exerciseGroup.get("exercises").get(currentExerciseIndex + 1);

            newExerciseId = newExercise.get("id");
            newExerciseGroupId = newExercise.get("exercise_group_id");
          }
        } else if (prev) {
          if (currentExerciseIndex == 0) {
            // current exercise is the first in this group, move to last exercise of previous group
            let nextExerciseGroup = state.verification.exercise_groups.get(exerciseGroupIndex - 1);

            newExerciseId = nextExerciseGroup.get("exercises").last().get("id");
            newExerciseGroupId = nextExerciseGroup.get("id");
          } else {
            // current exercise is in this group, go to next
            let newExercise = exerciseGroup.get("exercises").get(currentExerciseIndex - 1);

            newExerciseId = newExercise.get("id");
            newExerciseGroupId = newExercise.get("exercise_group_id");
          }
        }
      }
    });

    if (newExerciseId !== null && newExerciseGroupId !== null) {
      newSessionExercise = ExerciseUtils.getSessionExerciseFromExercise(newExerciseId);

      state = state.merge({
        currentExerciseId: newExerciseId,
        currentExerciseGroupId: newExerciseGroupId,
      });

      if (newSessionExercise !== null && newSessionExercise.get("not_viewed") && !SessionUtils.closedOrGraded()) {
        setTimeout(() => {
          ExerciseActions.view(newSessionExercise);
        }, 0);
      }
    }
  }
  return state.set("showResultDetails", false);
}

export default function reduce(state, action) {
  switch (action.type) {
    case Actions.SET_CURRENT:
      return setCurrent(state, action);

    case Actions.VIEW:
      return view(state, action);

    case Actions.TOGGLE_MARK:
      return toggleMark(state, action);

    case Actions.ANSWER:
      return answer(state, action);

    case Actions.NEXT:
      return next(state, action);

    case Actions.PREV:
      return prev(state, action);

    default:
      return state;
  }
}
