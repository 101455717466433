import React from "react";
import ClassNames from "classnames";
import i18n from "../../utils/i18n";
import GradeModal from "../modals/grade-modal";
import LayoutActions from "../../actions/layout-actions";
import SessionUtils from "../../utils/session-utils";

class GradeButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.openGradeModal = this.openGradeModal.bind(this);
  }

  disabled() {
    return this.props.store.reloading;
  }

  hidden() {
    return !SessionUtils.evaluationMode();
  }

  openGradeModal() {
    if (!this.disabled()) {
      LayoutActions.toggleGradeModal();
    }
  }

  render() {
    if (this.hidden()) {
      return null;
    } else {
      let buttonCssClass = ClassNames("button button-right no-border commit", {
        disabled: this.disabled(),
      });

      return (
        <div className="col-auto">
          <div className={buttonCssClass} onClick={this.openGradeModal}>
            {i18n.t("footer.grade")}
          </div>
          <GradeModal store={this.props.store} />
        </div>
      );
    }
  }
}

export default GradeButton;
