import Immutable from "immutable";
import Axios from "axios";
import Actions from "../constants/actions";
import BootActions from "../actions/boot-actions";
import ExerciseActions from "../actions/exercise-actions";
import Urls from "../utils/urls";
import ExerciseUtils from "../utils/exercise-utils";

import DataRecord from "../records/data-record.js";
import VerificationRecord from "../records/verification-record.js";

function setData(state, action) {
  let data = new DataRecord(Immutable.fromJS(action.data));

  return state.merge({
    data: data,
    dataLoaded: true,
  });
}

function loadVerification(state) {
  Axios.get(Urls.get("loadVerificationUrl"), {
    headers: { Authorization: state.data.apiToken },
  })
    .then(function (response) {
      BootActions.setVerification(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

  return state;
}

function setVerification(state, action) {
  let verification = new VerificationRecord(Immutable.fromJS(action.verificationData));

  let currentExerciseGroupId = null;
  let currentExerciseId = null;

  if (verification.exercise_groups.size > 0) {
    let firstExerciseGroup = verification.exercise_groups.first();
    currentExerciseGroupId = firstExerciseGroup.get("id");

    if (firstExerciseGroup.get("exercises").size > 0) {
      currentExerciseId = firstExerciseGroup.get("exercises").first().get("id");

      setTimeout(() => {
        ExerciseActions.view(ExerciseUtils.getSessionExerciseFromExercise(currentExerciseId));
      }, 0);
    }
  }

  return state.merge({
    verification: verification,
    verificationLoaded: true,
    currentExerciseGroupId: currentExerciseGroupId,
    currentExerciseId: currentExerciseId,
  });
}

export default function reduce(state, action) {
  switch (action.type) {
    case Actions.SET_DATA:
      return setData(state, action);

    case Actions.LOAD_VERIFICATION:
      return loadVerification(state, action);

    case Actions.SET_VERIFICATION:
      return setVerification(state, action);

    default:
      return state;
  }
}
