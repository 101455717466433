import React, { useCallback, useEffect, useRef, useState, useLayoutEffect } from "react";
import Axios from "axios";

import useDebounce from "../../hooks/useDebounce";

function ClassroomSelect({ apiToken, className, i18n, fieldNames, selectedClassroom, onClassroomChange }) {
  const [classrooms, setClassrooms] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 250);

  const loadClassrooms = useCallback(async () => {
    setLoading(true);
    const response = await Axios.get(`/api/classroom/classrooms`, {
      headers: { Authorization: apiToken },
      params: {
        name: search,
      },
    });
    setClassrooms(response.data);
    setLoading(false);
  }, [loading, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSelect = (e) => {
    const classroomId = parseInt(e.target.value, 10);
    onClassroomChange(classrooms.find((classroom) => classroom.id === classroomId));
  };

  useEffect(() => {
    loadClassrooms();
  }, [debouncedSearch]);

  return (
    <div className={`classroom-list-wrapper ${className}`}>
      {loading || classrooms === null ? (
        <p>{i18n.loading}</p>
      ) : classrooms.length === 0 ? (
        <p>{i18n.noClassrooms}</p>
      ) : (
        <>
          <div className="form-group">
            <input type="text" className="form-control" placeholder={i18n.newFromClassroomSearch} value={search} onChange={handleSearch} />
          </div>
          <div id="classroom-list" className="classroom-list">
            {classrooms.map((classroom) => (
              <div key={classroom.id} className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name={fieldNames.classroom}
                  value={classroom.id}
                  id={`classroom_${classroom.id}`}
                  data-classroom-name={classroom.name}
                  checked={selectedClassroom === classroom.id}
                  onChange={handleSelect}
                />
                <label htmlFor={`classroom_${classroom.id}`} className="form-check-label">
                  {classroom.name}
                </label>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ClassroomSelect;
