import Actions from "../constants/actions";
import Dispatcher from "../dispatcher/dispatcher";

const ExerciseGroupActions = {
  setActiveGroup(exerciseGroupId) {
    Dispatcher.dispatch({
      type: Actions.SET_ACTIVE_GROUP,
      exerciseGroupId,
    });
  },
};

export default ExerciseGroupActions;
