import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import Axios from "axios";

import { pluralize } from "../../lib/utils";

class GroupSelect extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      groupId: props.fieldValues.groupId,
      groupName: props.fieldValues.groupName,
      userIds: props.fieldValues.userIds,
      selectContentOpened: false,
      expandedGroupId: null,
      groupsLoaded: false,
      search: "",
      groups: [],
      filteredGroups: [],
    };

    this.toggleSelectContent = this.toggleSelectContent.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.debouncedSearch = debounce(this.performSearch, 250);

    this.groupIdRef = React.createRef();
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();

    this.fetchGroups();
  }

  render() {
    return (
      <div>
        {this.search()}
        {this.select()}
        {this.hiddenFields()}
      </div>
    );
  }

  search() {
    return (
      <div className="form-group">
        <input type="text" className="form-control" placeholder={this.props.i18n.groupSearch} value={this.state.search} onChange={this.handleSearch} />
      </div>
    );
  }

  select() {
    return (
      <div className="custom-select">
        <div className="custom-select-content">{this.groupsContent()}</div>
      </div>
    );
  }

  groupsContent() {
    if (this.state.groupsLoaded) {
      const groups = this.state.filteredGroups.length ? this.state.filteredGroups : this.state.groups;

      return (
        <div className="group-select-wrapper">
          {groups.length === 0 ? <div>{this.props.i18n.groupSelect.noGroups}</div> : groups.map((group, index) => this.groupContent(group, index === groups.length - 1))}
        </div>
      );
    } else {
      return (
        <div className="custom-select-item">
          <span>{this.props.i18n.groupSelect.loadingGroups}</span>
        </div>
      );
    }
  }

  groupContent(group, last) {
    let groupExpandIcon;
    if (!this.props.mobile) {
      let groupExpandIconClass = "fas fa-caret-right";
      if (this.isGroupExpanded(group.id)) {
        groupExpandIconClass = "fas fa-caret-down";
      }
      groupExpandIcon = (
        <div className="group-expand-icon" onClick={this.handleGroupExpand.bind(this, group)}>
          <i className={groupExpandIconClass} />
        </div>
      );
    }

    let groupSelectIconClass = "far fa-square icon-disabled";
    if (this.isGroupSelected(group.id) && this.props.userIds.length > 0) {
      groupSelectIconClass = "fas fa-minus-square";
    } else if (this.isGroupSelected(group.id)) {
      groupSelectIconClass = "fas fa-check-square";
    }
    const groupSelectIcon = <i className={groupSelectIconClass + " group-select-icon"} onClick={this.handleGroupSelect.bind(this, group)} />;

    return (
      <React.Fragment key={group.id}>
        <div className="custom-select-item group-select">
          <div className="group-select-item-header">
            {groupSelectIcon}
            <span onClick={this.handleGroupSelect.bind(this, group)} className="group-select-item-name">
              {group.name}{" "}
              {(this.isGroupExpanded(group.id) || this.isGroupSelected(group.id)) && (group.users.length > 0 || this.props.userIds.length > 0) && (
                <strong>{`(${pluralize(this.props.i18n.students, this.props.userIds.length || group.users.length)})`}</strong>
              )}
            </span>
            {groupExpandIcon}
          </div>
          {this.groupUsers(group)}
        </div>
        {!last && <hr />}
      </React.Fragment>
    );
  }

  groupUsers(group) {
    if (!this.isGroupExpanded(group.id)) return;

    if (group.users.length > 0) {
      return <div className="group-users">{group.users.map((user) => this.userContent(group, user))}</div>;
    } else {
      return <div className="group-users empty-group-users">{this.props.i18n.groupSelect.noStudents}</div>;
    }
  }

  userContent(group, user) {
    let userSelectIcon = "far fa-square icon-disabled";
    if (this.isUserSelected(user.id, group.id)) {
      userSelectIcon = "fas fa-check-square";
    } else if (this.isGroupSelected(group.id) && this.props.userIds.length === 0) {
      // group is selected, all users are included
      userSelectIcon = "fas fa-check-square icon-dimmed";
    }

    return (
      <div className="custom-select-item user-select" key={group.id + user.id}>
        <i className={userSelectIcon + " user-select-icon"} onClick={this.handleUserSelect.bind(this, group, user)} />
        <span onClick={this.handleUserSelect.bind(this, group, user)}>{user.fullname}</span>
      </div>
    );
  }

  hiddenFields() {
    let userIdsInputs = this.props.userIds.map((userId) => {
      return <input type="hidden" name={this.props.fieldNames.userIds} value={userId} key={userId + "-input"} />;
    });

    return (
      <div className="hidden-fields hidden">
        <input ref={this.groupIdRef} type="hidden" name={this.props.fieldNames.groupId} value={this.props.groupId} />
        {userIdsInputs}
      </div>
    );
  }

  // utilities

  isGroupSelected(groupId) {
    return this.props.groupId == groupId;
  }

  isGroupExpanded(groupId) {
    return this.state.expandedGroupId == groupId;
  }

  getGroup(groupId) {
    return this.state.groups.find(function (group) {
      return group.id === groupId;
    });
  }

  currentGroup() {
    return this.getGroup(this.props.groupId);
  }

  isUserSelected(userId, groupId) {
    let result = this.props.userIds.findIndex(function (selectedUserId) {
      return selectedUserId === userId;
    });
    return result >= 0 && this.isGroupSelected(groupId);
  }

  fetchGroups() {
    Axios.get(this.props.fetchGroupsUrl, {
      headers: { Authorization: this.props.apiToken },
    }).then((response) => {
      this.setState({
        groupsLoaded: true,
        groups: response.data,
      });
    });
  }

  // actions

  toggleSelectContent() {
    this.setState((prevState) => ({
      selectContentOpened: !prevState.selectContentOpened,
    }));
  }

  handleSearch(e) {
    this.setState({
      search: e.target.value,
    });
    this.debouncedSearch();
  }

  performSearch() {
    if (!this.state.groups.length) return;

    if (!this.state.search) {
      this.setState({
        filteredGroups: [],
      });
      return;
    }

    this.setState((oldState) => ({
      ...oldState,
      filteredGroups: oldState.groups.filter((group) => group.name.toLowerCase().includes(this.state.search.toLowerCase())),
    }));
  }

  handleGroupSelect(group) {
    if (this.isGroupSelected(group.id)) {
      this.deselectGroup();
    } else {
      let expandedGroupId = null;
      if (this.state.expandedGroupId === group.id) {
        expandedGroupId = group.id;
      }

      this.props.onChange({
        groupId: group.id,
        userIds: [],
        groupName: group.name,
      });
      this.setState({
        groupName: group.name,
        expandedGroupId: expandedGroupId,
      });
    }
  }

  handleGroupExpand(group) {
    if (this.isGroupExpanded(group.id)) {
      this.unexpandGroup();
    } else {
      this.setState({
        expandedGroupId: group.id,
      });
    }
  }

  handleUserSelect(group, user) {
    let newSelectedUserIds;
    if (this.isGroupSelected(group.id)) {
      // same group, let's clone current users selection
      newSelectedUserIds = this.props.userIds.slice(0);
      if (newSelectedUserIds.includes(user.id)) {
        // user is already selected, remove him
        newSelectedUserIds = newSelectedUserIds.filter(function (selectedUserId) {
          return selectedUserId !== user.id;
        });
      } else {
        // user is not selected, add him
        newSelectedUserIds.push(user.id);
      }
    } else {
      // group changed, must empty users selection & add user
      newSelectedUserIds = [user.id];
    }

    this.props.onChange({
      groupId: group.id,
      userIds: newSelectedUserIds,
      groupName: group.name,
    });
    this.setState({
      groupName: group.name,
    });
  }

  deselectGroup() {
    this.props.onChange({
      groupId: "",
      userIds: [],
      groupName: "",
    });
  }

  unexpandGroup() {
    this.setState({
      expandedGroupId: null,
    });
  }
}

GroupSelect.propTypes = {
  i18n: PropTypes.object.isRequired,
  fieldNames: PropTypes.object.isRequired,
  fieldValues: PropTypes.object.isRequired,
  fetchGroupsUrl: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
};

export default GroupSelect;
