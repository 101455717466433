import Actions from "../constants/actions";
import Dispatcher from "../dispatcher/dispatcher";

const BootActions = {
  setData(data) {
    Dispatcher.dispatch({
      type: Actions.SET_DATA,
      data,
    });
  },
  loadVerification(verificationId) {
    Dispatcher.dispatch({
      type: Actions.LOAD_VERIFICATION,
      verificationId,
    });
  },
  setVerification(verificationData) {
    Dispatcher.dispatch({
      type: Actions.SET_VERIFICATION,
      verificationData,
    });
  },
};

export default BootActions;
