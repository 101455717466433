import React from "react";
import PropTypes from "prop-types";
import { Progress } from "react-sweet-progress";

class ScoreProgress extends React.PureComponent {
  status() {
    if (this.props.success) {
      return "success";
    } else {
      return "error";
    }
  }

  mode() {
    if (this.props.scorePercentage !== undefined) {
      return "percentage";
    } else if (this.props.rawScore !== undefined && this.props.maxScore !== undefined) {
      return "raw";
    }
  }

  theme() {
    let trailColor = !!this.props.successRedTrail ? "#ff9999" : "#bdf3d2";
    return {
      error: {
        symbol: this.props.content,
        trailColor: "#edccd1",
        color: "#fb0007",
      },
      success: {
        symbol: this.props.content,
        trailColor: trailColor,
        color: "#1bcc46",
      },
    };
  }

  percent() {
    if (this.props.scorePercentage == undefined || this.props.scorePercentage <= 0) {
      // progress circle doesn't display at all with 0%
      return 0.1;
    } else {
      return this.props.scorePercentage;
    }
  }

  render() {
    return <Progress type="circle" width={this.props.width} strokeWidth={this.props.strokeWidth} status={this.status()} percent={this.percent()} theme={this.theme()} />;
  }
}

ScoreProgress.propTypes = {
  width: PropTypes.number,
  strokeWidth: PropTypes.number,
  success: PropTypes.bool.isRequired,
  content: PropTypes.string,
  scorePercentage: PropTypes.number,
  successRedTrail: PropTypes.bool,
};

ScoreProgress.defaultProps = {
  width: 80,
  strokeWidth: 8,
  success: false,
};

export default ScoreProgress;
