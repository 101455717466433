import Immutable from "immutable";
import AssignmentRecord from "./assignment-record.js";

export default Immutable.Record({
  id: "",
  state: "",
  elapsed_seconds: 0,
  verification_id: "",
  raw_score: null,
  score: null,
  score_base_10: null,
  score_percentage: null,
  max_score: null,
  success: null,
  teacher_comment: "",
  no_average: false,
  assignment: new AssignmentRecord(),
  session_exercises: Immutable.List(),
});
