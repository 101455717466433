import { io } from "socket.io-client";
import Utils from "./utils";
import SocketActions from "../actions/socket-actions";

class Socket extends Utils {
  static init(data) {
    let socket = io(data.socketHost, {
      withCredentials: true,
      transports: ["websocket", "polling"],
      secure: true,
    });

    socket.on("connect", function () {
      // console.log(socket.id);
      // console.log(socket.connected);
      SocketActions.connected();

      socket.emit("start session", {
        sessionId: data.sessionId,
        verificationId: data.verificationId,
        token: data.apiToken,
        signature: data.socketSignature,
        userId: data.userId,
      });
    });

    socket.on("disconnect", function () {
      SocketActions.disconnected();
    });

    socket.on("session started", function (data) {
      SocketActions.phaseElapsedSeconds(data.elapsed_seconds);
    });

    return socket;
  }

  static connected() {
    // console.log(this.store().socketConnected);
    return this.store().socketConnected && this.store().socket !== null && this.store().socket.connected;
  }
}

export default Socket;
