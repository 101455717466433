import React from "react";
import SidebarButton from "./sidebar-button";
import MarkButton from "./mark-button";
import PrevButton from "./prev-button";
import NextButton from "./next-button";
import CommitButton from "./commit-button";
import GradeButton from "./grade-button";

class Footer extends React.PureComponent {
  render() {
    return (
      <div className="session-footer container-fluid">
        <div className="row">
          <SidebarButton store={this.props.store} />
          <MarkButton store={this.props.store} />
          <PrevButton store={this.props.store} />
          <NextButton store={this.props.store} />
          <CommitButton store={this.props.store} />
          <GradeButton store={this.props.store} />
        </div>
      </div>
    );
  }
}

export default Footer;
