import React from "react";
import ClassNames from "classnames";
import ExerciseActions from "../../actions/exercise-actions";
import ExerciseUtils from "../../utils/exercise-utils";
import SessionUtils from "../../utils/session-utils";

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.store.currentExerciseId !== this.props.store.currentExerciseId || nextProps.store.showSidebar !== this.props.store.showSidebar;
  }

  componentDidMount() {
    this.runPlayer();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.runPlayer();
  }

  submit(output) {
    ExerciseActions.answer(output);
  }

  userData() {
    let currentExercise = ExerciseUtils.getCurrentExercise();
    let currentSessionExercise = ExerciseUtils.getCurrentSessionExercise();

    return {
      id: currentSessionExercise.get("id"),
      title: currentExercise.get("title"),
      assets: [{ filename: "answers.json" }],
      user_id: this.props.store.data.userId,
    };
  }

  resource() {
    let currentExercise = ExerciseUtils.getCurrentExercise();
    let assets = [{ filename: "content.json" }];

    if (currentExercise.get("assets") !== undefined && currentExercise.get("assets").size > 0) {
      currentExercise.get("assets").forEach((asset) => {
        assets.push({
          filename: asset.get("filename"),
          url: asset.get("url"),
        });
      });
    }

    return {
      id: currentExercise.get("id"),
      title: currentExercise.get("title"),
      assets: assets,
      user_id: this.props.store.data.userId,
    };
  }

  answers() {
    if (ExerciseUtils.getCurrentSessionExercise() !== null && ExerciseUtils.getCurrentSessionExercise() !== undefined && ExerciseUtils.getCurrentSessionExercise().get("answers") !== null) {
      return ExerciseUtils.getCurrentSessionExercise().get("answers").toJS();
    }
  }

  showResults() {
    return SessionUtils.graded() || (SessionUtils.closed() && !SessionUtils.studentMode());
  }

  readOnly() {
    return SessionUtils.ownerMode() || SessionUtils.closed() || SessionUtils.graded();
  }

  showHelp() {
    return SessionUtils.easyTraining() || (SessionUtils.training() && SessionUtils.graded());
  }

  showSolutions() {
    return SessionUtils.previewMode() || SessionUtils.graded() || (SessionUtils.autoMode() && SessionUtils.closed()) || (SessionUtils.ownerMode() && (SessionUtils.started() || SessionUtils.closed()));
  }

  autoPlay() {
    return SessionUtils.started() && (SessionUtils.autoMode() || SessionUtils.studentMode() || SessionUtils.previewMode()) && SessionUtils.simulationOrOfficial();
  }

  twoColumns() {
    // return window.matchMedia('(min-width: 992px)').matches
    return true;
  }

  buildEmptyOptions() {
    let userData = {
      id: null,
      title: null,
      assets: [],
      user_id: null,
      // resource_code: null,
      // resource_type_id: null,
    };

    let resource = {
      id: null,
      title: null,
      assets: [],
      user_id: null,
      // resource_code: null,
      // resource_type_id: null,
    };

    return {
      showResults: false,
      readOnly: false,
      showHelp: false,
      showSolutions: false,
      twoColumns: this.twoColumns(),
      resource,
      userData,
      fetchAsset: (asset, callback) => {},
    };
  }

  unmountExercisePlayer() {
    // initialize an empty exercise player to run onUnmount callback on current exercise
    bSmartUi.renderExercisesPlayer("session-player", this.buildEmptyOptions());
  }

  runPlayer() {
    if (this.props.store.currentExerciseId == null) {
      this.runEmptyPlayer();
    }

    // initialize a working exercise player with new selected exercise
    if (this.props.store.currentExerciseId !== null) {
      let userData = this.userData();
      let resource = this.resource();
      let content = ExerciseUtils.getCurrentExercise().get("structure").toJS();
      let answers = this.answers();
      let submit = this.submit.bind(this);

      bSmartUi.renderExercisesPlayer("session-player", {
        showResults: this.showResults(),
        readOnly: this.readOnly(),
        showHelp: this.showHelp(),
        showSolutions: this.showSolutions(),
        autoPlay: this.autoPlay(),
        twoColumns: this.twoColumns(),
        resource,
        userData,

        fetchAsset: (asset, callback) => {
          let xhr;

          const url = [".jpg", ".png", ".gif", ".mp4", ".mp3"];
          const text = [".html", ".htm", ".txt"];
          const index = asset.filename.lastIndexOf(".");
          const extension = asset.filename.slice(index);

          if (url.includes(extension)) {
            setTimeout(() => callback(null, asset.url), 0);
            return;
          }

          switch (asset.filename) {
            case "content.json":
              setTimeout(() => callback(null, content), 0);
              break;

            case "answers.json":
              setTimeout(() => callback(null, answers), 0);
              break;

            default:
              xhr = new XMLHttpRequest();

              if (text.includes(extension)) {
                xhr.responseType = "text";
              } else {
                xhr.responseType = "arraybuffer";
              }

              xhr.onerror = function () {
                callback("errore caricamento asset!");
              };
              xhr.onload = function () {
                callback(null, this.response);
              };
              xhr.open("GET", asset.url);
              xhr.send();
              break;
          }
        },
        onUnmount: (err, output) => {
          submit(output);
        },
      });
    }
  }

  runEmptyPlayer() {
    let resource = {};
    let userData = {};

    bSmartUi.renderExercisesPlayer("session-player", {
      resource,
      userData,
      fetchAsset: (asset, callback) => {},
    });
  }

  render() {
    let playerCssClass = ClassNames("session-player", {
      "full-screen": SessionUtils.mobileLayout() || !this.props.store.showSidebar,
    });

    return <div className={playerCssClass} id="session-player" ref={this.playerRef} />;
  }
}

export default Player;
