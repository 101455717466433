import Utils from "./utils";

class ExerciseUtils extends Utils {
  // session exercises

  static getSessionExercise(sessionExerciseId) {
    return this.store().session.session_exercises.find((sessionExercise) => {
      return sessionExercise.get("id") === sessionExerciseId;
    });
  }

  static getCurrentSessionExercise() {
    if (this.getCurrentExercise() !== undefined) {
      return this.getSessionExerciseFromExercise(this.getCurrentExercise().get("id"));
    }
  }

  static getSessionExerciseFromExercise(exerciseId) {
    return this.store().session.session_exercises.find((sessionExercise) => {
      return sessionExercise.get("exercise_id") === exerciseId;
    });
  }

  static sessionExerciseStatus(sessionExercise) {
    let exercise = this.getExerciseFromSessionExercise(sessionExercise);
    if (sessionExercise === undefined || exercise === undefined) return;

    if (sessionExercise.get("success") === true && (exercise.get("open_items") === 0 || sessionExercise.get("raw_teacher_scores") !== null)) {
      return "success";
    } else if (
      (sessionExercise.get("success") === false || sessionExercise.get("success") === undefined || sessionExercise.get("success") === null) &&
      (exercise.get("open_items") === 0 || sessionExercise.get("raw_teacher_scores") !== null)
    ) {
      return "failure";
    } else {
      return "toEvaluate";
    }
  }

  // exercises

  static getExercise(exerciseId) {
    let foundExercise;
    this.store().verification.exercise_groups.forEach((exerciseGroup) => {
      exerciseGroup.get("exercises").forEach((exercise) => {
        if (exerciseId == exercise.get("id")) {
          foundExercise = exercise;
        }
      });
    });

    return foundExercise;
  }

  static getCurrentExercise() {
    return this.getExercise(this.store().currentExerciseId);
  }

  static getExerciseFromSessionExercise(sessionExercise) {
    return this.getExercise(sessionExercise.get("exercise_id"));
  }

  static isFirstExercise() {
    let currentExerciseId = this.getCurrentExercise().get("id");
    let firstExerciseId = this.store().verification.exercise_groups.first().get("exercises").first().get("id");

    return currentExerciseId == firstExerciseId;
  }

  static isLastExercise() {
    let currentExerciseId = this.getCurrentExercise().get("id");
    let lastExerciseId = this.store().verification.exercise_groups.last().get("exercises").last().get("id");

    return currentExerciseId == lastExerciseId;
  }
}

export default ExerciseUtils;
