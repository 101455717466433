class Cookie {
  COOKIE_KEY = "allow_cookies";

  cookiesBar = null;
  cookiesBarLink = null;

  constructor() {
    this.bindElements();
    this.setupListeners();
  }

  bindElements() {
    this.cookiesBar = document.getElementById("cookies_bar");
    this.cookiesBarLink = this.cookiesBar && this.cookiesBar.querySelector(".cookies-bar__link");
  }

  setupListeners() {
    if (this.cookiesBarLink) {
      this.cookiesBarLink.addEventListener("click", (event) => {
        event.preventDefault();

        this.setCookie(this.COOKIE_KEY, "true");
        this.cookiesBar.classList.add("d-none");
      });
    }
  }

  getCookie(searchKey) {
    const cookies = document.cookie.split("; ");

    for (const cookie of cookies) {
      const [key, value] = cookie.split("=");

      if (key === searchKey) {
        return value;
      }
    }
  }

  setCookie(key, value) {
    if (!this.getCookie(key)) {
      document.cookie = `${key}=${value}; ${document.cookie}`;
      return true;
    }

    return false;
  }
}

export default Cookie;
