import Actions from "../constants/actions";
import Dispatcher from "../dispatcher/dispatcher";

const SocketActions = {
  init() {
    Dispatcher.dispatch({
      type: Actions.INIT,
    });
  },
  connected() {
    Dispatcher.dispatch({
      type: Actions.CONNECTED,
    });
  },
  disconnected() {
    Dispatcher.dispatch({
      type: Actions.DISCONNECTED,
    });
  },
  phaseElapsedSeconds(elapsedSeconds) {
    Dispatcher.dispatch({
      type: Actions.PHASE_ELAPSED_SECONDS,
      elapsedSeconds,
    });
  },
};

export default SocketActions;
