import BuildUrl from "build-url";
import Utils from "./utils";

class Urls extends Utils {
  static get(key, params) {
    return BuildUrl(null, {
      path: this.store().data.urls.get(key),
      queryParams: params,
    });
  }
}

export default Urls;
