import React from "react";
import ClassNames from "classnames";
import SessionUtils from "../../utils/session-utils";
import EvaluationActions from "../../actions/evaluation-actions";
import ExerciseUtils from "../../utils/exercise-utils";
import i18n from "../../utils/i18n";

class ExerciseEvaluation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      comment: "",
      commentExpanded: false,
    };

    this.changeComment = this.changeComment.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.focusComment = this.focusComment.bind(this);
    this.cancelComment = this.cancelComment.bind(this);
    this.changeScore = this.changeScore.bind(this);
  }

  componentDidMount() {
    if (this.sessionExercise() !== undefined) {
      this.setState({
        comment: this.sessionExercise().get("teacher_comment"),
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.sessionExercise() !== undefined && prevProps.store.currentExerciseId !== this.exercise().get("id")) {
      this.setState({
        comment: this.sessionExercise().get("teacher_comment"),
      });
    }
  }

  // actions

  focusComment() {
    this.setState({
      commentExpanded: true,
    });
  }

  cancelComment() {
    this.setState({
      comment: this.sessionExercise().get("teacher_comment"),
      commentExpanded: false,
    });
  }

  changeComment(event) {
    this.setState({
      comment: event.target.value,
    });
  }

  submitComment(event) {
    event.preventDefault();
    EvaluationActions.comment(this.sessionExercise(), this.state.comment);
    this.setState({
      commentExpanded: false,
    });
  }

  closeEvaluation() {
    EvaluationActions.setEvaluating(null);
  }

  changeScore(event) {
    EvaluationActions.updateScore(this.sessionExercise(), event.target.value);
  }

  // utilities

  exercise() {
    return ExerciseUtils.getCurrentExercise();
  }

  sessionExercise() {
    return ExerciseUtils.getCurrentSessionExercise();
  }

  success() {
    return ExerciseUtils.sessionExerciseStatus(this.sessionExercise()) === "success";
  }

  failure() {
    return ExerciseUtils.sessionExerciseStatus(this.sessionExercise()) === "failure";
  }

  totalScore() {
    return this.sessionExercise().get("raw_auto_scores") + this.sessionExercise().get("raw_teacher_scores");
  }

  // content

  result() {
    if (!this.props.store.data.hasScoreRule) {
      if (this.success()) {
        return <h4 className="text-success">{i18n.t("sidebar.evaluation.success")}</h4>;
      } else if (this.failure()) {
        return <h4 className="text-danger">{i18n.t("sidebar.evaluation.failure")}</h4>;
      } else {
        return <h4>{i18n.t("sidebar.evaluation.toEvaluate")}</h4>;
      }
    } else {
      return;
    }
  }

  legacy_score() {
    let autoItems;
    let todoItems;
    let itemsTotal;
    let closedItems = this.exercise().get("closed_items");
    let openItems = this.exercise().get("open_items");

    if (closedItems > 0) {
      let autoOptions = [];
      let rawAutoScores = this.sessionExercise().get("raw_auto_scores") || 0;
      let i = 0;
      for (i = 0; i <= closedItems; i++) {
        autoOptions.push(
          <option key={i} value={i}>
            {i} / {closedItems} {i18n.t("sidebar.evaluation.item")}
          </option>
        );
      }

      autoItems = (
        <div className="score form-group">
          <label>{i18n.t("sidebar.evaluation.autoItems")}</label>
          <select value={rawAutoScores} disabled={true} className="form-control">
            {autoOptions}
          </select>
        </div>
      );
    }

    if (openItems > 0) {
      let todoOptions = [];
      let rawTeacherScores = this.sessionExercise().get("raw_teacher_scores") || 0;
      let i = 0;
      for (i = 0; i <= openItems; i++) {
        todoOptions.push(
          <option key={i} value={i}>
            {i} / {openItems} {i18n.t("sidebar.evaluation.item")}
          </option>
        );
      }

      todoItems = (
        <div className="score form-group">
          <label>{i18n.t("sidebar.evaluation.todoItems")}</label>
          <select value={rawTeacherScores} onChange={this.changeScore} className="form-control">
            {todoOptions}
          </select>
        </div>
      );
    }

    if (openItems > 0 && closedItems > 0) {
      itemsTotal = (
        <div className="score-total">
          {i18n.t("sidebar.evaluation.correctTotalItems")}: {this.totalScore()}
        </div>
      );
    }

    return (
      <div className="score-wrapper">
        {autoItems}
        {todoItems}
        {itemsTotal}
      </div>
    );
  }

  score() {
    let autoAnswers;
    let todoAnswers;
    let answersTotal;
    let closedAnswers = this.exercise().get("closed_answers");
    let openAnswers = this.exercise().get("open_answers");

    if (closedAnswers > 0) {
      let autoOptions = [];
      let rawAutoScores = this.sessionExercise().get("raw_auto_scores") || 0;
      let i = 0;
      for (i = 0; i <= closedAnswers; i++) {
        autoOptions.push(
          <option key={i} value={i}>
            {i} / {closedAnswers} {i18n.t("sidebar.evaluation.answer")}
          </option>
        );
      }

      autoAnswers = (
        <div className="score form-group">
          <label>{i18n.t("sidebar.evaluation.autoAnswers")}</label>
          <select value={rawAutoScores} disabled={true} className="form-control">
            {autoOptions}
          </select>
        </div>
      );
    }

    if (openAnswers > 0) {
      let todoOptions = [];
      let rawTeacherScores = this.sessionExercise().get("raw_teacher_scores") || 0;
      let i = 0;
      for (i = 0; i <= openAnswers; i++) {
        todoOptions.push(
          <option key={i} value={i}>
            {i} / {openAnswers} {i18n.t("sidebar.evaluation.answer")}
          </option>
        );
      }

      todoAnswers = (
        <div className="score form-group">
          <label>{i18n.t("sidebar.evaluation.todoAnswers")}</label>
          <select value={rawTeacherScores} onChange={this.changeScore} className="form-control">
            {todoOptions}
          </select>
        </div>
      );
    }

    if (openAnswers > 0 && closedAnswers > 0) {
      answersTotal = (
        <div className="score-total">
          {i18n.t("sidebar.evaluation.correctTotalAnswers")}: {this.totalScore()}
        </div>
      );
    }

    return (
      <div className="score-wrapper">
        {autoAnswers}
        {todoAnswers}
        {answersTotal}
      </div>
    );
  }

  comment() {
    let actions;
    if (this.state.commentExpanded) {
      actions = (
        <div className="row">
          <div className="col">
            <div className="btn btn-block btn-outline-dark" onClick={this.cancelComment}>
              {i18n.t("sidebar.evaluation.cancelComment")}
            </div>
          </div>
          <div className="col">
            <input type="submit" value={i18n.t("sidebar.evaluation.confirmComment")} className="btn btn-block btn-primary" />
          </div>
        </div>
      );
    }

    return (
      <form className="comment" onSubmit={this.submitComment}>
        <div className="form-group">
          <label htmlFor="comment">{i18n.t("sidebar.evaluation.comment")}</label>
          <textarea
            id="comment"
            rows={3}
            className="form-control"
            value={this.state.comment}
            placeholder={i18n.t("sidebar.evaluation.commentPlaceholder")}
            onChange={this.changeComment}
            onFocus={this.focusComment}
          />
        </div>
        {actions}
      </form>
    );
  }

  howTo() {
    return (
      <div className="how-to">
        <label>{i18n.t("sidebar.evaluation.howTo")}</label>
        <h6>
          {i18n.t("sidebar.evaluation.groupTitle")}
          &nbsp;
          {this.exercise().get("group")}
        </h6>
        <p>{i18n.t("sidebar.evaluation.group")}</p>
      </div>
    );
  }

  evaluatePaddingTop() {
    let e = document.getElementById("session-sidebar-bottom");
    return {
      paddingTop: ((e && e.scrollTop) || 0) + "px",
    };
  }

  render() {
    let isLegacyScoreMode = this.props.store.data.isLegacyScoreMode;
    const renderScore = () => {
      if (isLegacyScoreMode) {
        return this.legacy_score();
      } else {
        return this.score();
      }
    };

    return (
      <div className="container-fluid exercise-evaluation" style={this.evaluatePaddingTop()}>
        <div className="relative-wrapper">
          <i className="fas fa-times-circle close" onClick={this.closeEvaluation.bind(this)} />
          <h2>{this.exercise().get("title")}</h2>
          {this.result()}
          {renderScore()}
          {this.comment()}
          {this.howTo()}
        </div>
      </div>
    );
  }
}

export default ExerciseEvaluation;
