const btnLoadingCompleted = function () {
  $(".btn-loading").removeClass("loading");
};

const toggleActionBtn = function () {
  let btn = $(".toggle-action-btn");
  $(btn).addClass("disabled");
  $(btn).html('<i class="fas fa-sync fa-spin"/>');
};

export function downloadArchive(filename, shuffle_exercises, apiUrl) {
  const payload = {
    verification_export_pdf_form: {
      filename,
      shuffle_exercises,
    },
  };

  return fetch(apiUrl, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": $("input[name='authenticity_token']").val(),
    },
    body: JSON.stringify(payload),
  })
    .then((resp) => resp.blob({ type: "application/octet-stream" }))
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename + ".zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      window.remoteActions.hideModal();
    });
}

const showWorkingButton = function () {
  $("#pdfexport").submit((event) => {
    event.preventDefault();
    $(".btn-working").removeClass("d-none");
    $(".btn-submit").addClass("d-none");

    const filename = $("#verification_export_pdf_form_filename")[0].value.replace(/[^a-z0-9]/gi, "_");
    var shuffleExercisesCheckboxPresent = $("#verification_export_pdf_form_shuffle_exercises").length !== 0;
    const shuffleExercises = shuffleExercisesCheckboxPresent ? $("#verification_export_pdf_form_shuffle_exercises")[0].checked : false;
    const url = $("#pdfexport").attr("action");

    downloadArchive(filename, shuffleExercises, url);
  });
};

export default {
  btnLoadingCompleted: btnLoadingCompleted,
  toggleActionBtn: toggleActionBtn,
  showWorkingButton: showWorkingButton,
};
