import utilities from "./utilities";
import modal from "./modal";

const refreshRunningImports = function (content) {
  $(".running-imports").html(content);
};

const pollRunningImports = function () {
  if ($(".running-imports").length > 0) {
    let poll = function () {
      $(".running-imports-refresh")[0].click();
    };
    setInterval(poll, 3000);
  }
};

export default {
  refreshRunningImports: refreshRunningImports,
  pollRunningImports: pollRunningImports,
};
