import React from "react";
import PropTypes from "prop-types";
import i18n from "../../utils/i18n";
import Urls from "../../utils/urls";
import { downloadArchive } from "../../../../modules/utilities";

export default class PrintModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();

    const store = this.props.store;
    const data = store.data;

    this.state = {
      filename: data.defaultPdfArchiveFilename,
      shuffle: false,
      loading: false,
    };
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip({ boundary: "window" });
  }

  doDownloadArchive = () => {
    const url = Urls.get("printUrl");
    const filename = this.state.filename;
    const shuffle = this.state.shuffle;

    this.setState({ loading: true });

    downloadArchive(filename, shuffle, url).then(() => {
      $(this.modalRef.current).modal("hide");
      this.setState({ loading: false });
    });
  };

  getVerificationShuffle() {
    const store = this.props.store;
    const data = store.data;

    if (!data.allowExerciseShuffle) {
      return null;
    }

    const __html = i18n.t("modals.pdfExport.form.shuffleExercisesLabel");
    return (
      <div className="export-options">
        <div className="text font-weight-bold mt-4">{i18n.t("modals.pdfExport.form.exportOptions")}</div>
        <div className="form-check">
          <input
            className="form-check-input boolean optional"
            type="checkbox"
            value={this.state.shuffle}
            name="verification_export_pdf_form[shuffle_exercises]"
            id="verification_export_pdf_form_shuffle_exercises"
            onChange={this.setShuffle}
          />
          <label className="form-check-label boolean optional" htmlFor="verification_export_pdf_form_shuffle_exercises" dangerouslySetInnerHTML={{ __html }} />
        </div>
      </div>
    );
  }

  getSubmit = () => {
    const valid = this.getValidation() === null;
    const loading = this.state.loading;

    if (loading) {
      return (
        <div className="btn btn-lg btn-primary btn-working disabled">
          <i className="fas fa-sync fa-spin ml-2" />
        </div>
      );
    }

    return (
      <button type="button" className="btn btn-lg btn-primary btn-submit" disabled={!valid} onClick={this.doDownloadArchive}>
        {i18n.t("modals.pdfExport.form.exportButtonText")}
      </button>
    );
  };

  getValidation() {
    const filename = this.state.filename;
    const forbidden = /[\x00\/\\:\*\?\"<>\|]/.test(filename);

    if (filename.length === 0 || forbidden) {
      return <span class="label label-danger">{i18n.t("modals.pdfExport.form.invalidFilename")}</span>;
    }

    return null;
  }

  setFilename = (evt) => {
    this.setState({ filename: evt.target.value });
  };

  setShuffle = () => {
    this.setState((prevState) => ({ shuffle: !prevState.shuffle }));
  };

  render() {
    const store = this.props.store;
    const data = store.data;

    const previewLabel = i18n.t("modals.pdfExport.form.text1");
    const filenameValid = this.getValidation() === null;
    const validClass = filenameValid ? "is-valid" : "is-invalid";
    const filenameClass = `form-control ${validClass} string required`;

    return (
      <div className="modal" id="printModal" tabIndex="-1" role="dialog" aria-labelledby="printModal" aria-hidden="true" ref={this.modalRef}>
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="close close-absolute" data-dismiss="modal">
              <i className="fas fa-times" />
            </div>
            <div className="modal-body">
              <div className="verification-show">
                <h6 className="publisher">{i18n.t("modals.pdfExport.form.titleHint")}</h6>
                <h4>{data.title}</h4>
              </div>

              <div className="custom-tabs">
                <div className="tab-content">
                  <div id="#description" className="tab pane active">
                    <div className="tab-wrapper">
                      <p className="small group-invite-preview" dangerouslySetInnerHTML={{ __html: previewLabel }} />
                      <br />
                      <label className="form-control-label string required" htmlFor="verification_export_pdf_form_filename">
                        {i18n.t("modals.pdfExport.form.filenameLabel")} <abbr title="richiesto">*</abbr>
                      </label>
                      <input
                        name="verification_pdfExport_form[filename]"
                        id="verification_pdfExport_form_filename"
                        className={filenameClass}
                        type="text"
                        value={this.state.filename}
                        onChange={this.setFilename}
                      />
                      {this.getValidation()}
                      {this.getVerificationShuffle()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-lg btn-outline-secondary mr-auto" data-dismiss="modal">
                {i18n.t("modals.cancel")}
              </button>
              {this.getSubmit()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrintModal.propTypes = {
  store: PropTypes.object.isRequired,
};
