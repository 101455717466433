import Axios from "axios";
import debounce from "lodash/debounce";

import modal from "./modal";

function show(content, token) {
  function handleClassroomSearch() {
    const val = $(this).val();

    Axios.get(`/api/classroom/classrooms?name=${val}`, {
      headers: { Authorization: token },
    }).then((res) => {
      const html = res.data
        ? res.data
            .map(
              (classroom) => `
        <div class="form-check">
          <input class="form-check-input" type="radio" name="classroom" value="${classroom.id}" id="classroom_${classroom.id}">
          <label class="form-check-label" for="classroom_${classroom.id}">${classroom.name}</label>
        </div>
      `
            )
            .join("")
        : "";
      $("#classroom-list").html(html);
    });
  }

  const callback = debounce(handleClassroomSearch, 250);

  $(document).off("keyup", "#classroom-list-search", callback);
  $(document).on("keyup", "#classroom-list-search", callback);
  modal.show(content);
}

export default {
  show: show,
};
