import utilities from "./utilities";
import modal from "./modal";
import initPickers from "./pickers";

var ReactRailsUJS = require("react_ujs");

const openVerificationAssignSidebar = function (sidebarContent) {
  modal.hide();
  modal.show(sidebarContent);
  utilities.btnLoadingCompleted();
  initPickers();
  ReactRailsUJS.unmountComponents(modal.wrapperClass);
  ReactRailsUJS.mountComponents(modal.wrapperClass);
};

const openAssignmentModal = function (modalContent) {
  modal.hide();
  modal.show(modalContent);
  utilities.btnLoadingCompleted();
};

const openAssignmentSessionModal = function (modalContent) {
  modal.show(modalContent);
  ReactRailsUJS.mountComponents();
};

const substituteModal = function (modalContent) {
  $("#assignment-modal .modal-content").html(modalContent);
};

export default {
  openVerificationAssignSidebar: openVerificationAssignSidebar,
  openAssignmentModal: openAssignmentModal,
  openAssignmentSessionModal: openAssignmentSessionModal,
  substituteModal,
};
