import Actions from "../constants/actions";
import Socket from "../utils/socket";
import SessionUtils from "../utils/session-utils";

function init(state) {
  if (state.socket == null && SessionUtils.playMode()) {
    let socket = Socket.init(state.data);
    state = state.set("socket", socket);
  }

  return state;
}

function connected(state) {
  return state.set("socketConnected", true);
}

function disconnected(state) {
  return state.set("socketConnected", false);
}

function phaseElapsedSeconds(state, action) {
  return state.set("elapsedSeconds", action.elapsedSeconds);
}

export default function reduce(state, action) {
  switch (action.type) {
    case Actions.INIT:
      return init(state, action);

    case Actions.CONNECTED:
      return connected(state, action);

    case Actions.DISCONNECTED:
      return disconnected(state, action);

    case Actions.PHASE_ELAPSED_SECONDS:
      return phaseElapsedSeconds(state, action);

    default:
      return state;
  }
}
