import Rails from "@rails/ujs";

const btnLoading = function () {
  $(".btn-loading").click(function () {
    $(this).addClass("loading");
  });
};

const closeFlashMessage = function () {
  $(".close-flash").click(function (event) {
    event.preventDefault();
    $(this).closest(".badge").fadeOut();
  });
};

const autoLogin = function () {
  if ($(".auto-login-link").length > 0) {
    window.location.href = $(".auto-login-link").attr("href");
  }
};

const initTooltips = function () {
  $('[data-toggle="tooltip"]').tooltip();
};

const scrollAssignmentResults = function () {
  $(".scroll-col-left").click(function () {
    var pos = $(".results-col").scrollLeft();
    $(".results-col").animate({ scrollLeft: pos - $(".score-col").width() }, 200);
  });
  $(".scroll-col-right").click(function (event) {
    var pos = $(".results-col").scrollLeft();
    $(".results-col").animate({ scrollLeft: pos + $(".score-col").width() }, 200);
  });

  if ($(".results-row").outerWidth() <= $(".results-col").outerWidth()) {
    $(".scroll-col-left").hide();
    $(".scroll-col-right").hide();
  }
};

const resetNotices = function () {
  if ($(".reset-notice").length > 0) {
    $(".reset-notice").each(function () {
      Rails.fire($(this).get(0), "submit");
    });
  }
};

const clearTextInput = function () {
  $(".has-clear input")
    .on("input propertychange", function () {
      var visible = Boolean($(this).val());
      $(this).siblings(".form-control-clear").toggleClass("d-none", !visible);
    })
    .trigger("propertychange");

  $(".form-control-clear").click(function () {
    $(this).siblings("input").val("").trigger("propertychange").focus();
  });
};

export default function () {
  btnLoading();
  closeFlashMessage();
  autoLogin();
  initTooltips();
  scrollAssignmentResults();
  resetNotices();
  clearTextInput();
}
