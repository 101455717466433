import Immutable from "immutable";

export default Immutable.Record({
  exercisesViewed: 0,
  exercisesAnswered: 0,
  exercisesNotAnswered: 0,
  exercisesMarked: 0,
  exercisesCount: 0,
  exercisesCorrect: 0,
  exercisesIncorrect: 0,
  exercisesNotEvaluated: 0,
});
