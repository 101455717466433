import React from "react";
import ClassNames from "classnames";
import i18n from "../../utils/i18n";
import SessionUtils from "../../utils/session-utils";
import LayoutActions from "../../actions/layout-actions";
import Counters from "./counters";
import MobileProgress from "./mobile-progress";
import Result from "./result";
import Exercises from "./exercises";
import ExercisesNotAnswered from "./exercises-not-answered";
import ExercisesMarked from "./exercises-marked";

class Sidebar extends React.PureComponent {
  tabCssClass(tab) {
    return ClassNames("sidebar-tab col", {
      active: tab == this.props.store.activeExercisesTab,
      last: tab == "exercisesMarked",
    });
  }

  activeTab() {
    let ActiveExerciseComponent = Exercises;
    if (this.props.store.activeExercisesTab == "exercisesNotAnswered") {
      ActiveExerciseComponent = ExercisesNotAnswered;
    } else if (this.props.store.activeExercisesTab == "exercisesMarked") {
      ActiveExerciseComponent = ExercisesMarked;
    }

    return <ActiveExerciseComponent store={this.props.store} />;
  }

  sidebarTop() {
    if (SessionUtils.resultMode()) {
      return <Result store={this.props.store} />;
    } else if (SessionUtils.mobileLayout()) {
      return <MobileProgress store={this.props.store} />;
    } else {
      return (
        <React.Fragment>
          <Counters store={this.props.store} />
          <div className="sidebar-tabs d-none d-md-flex">
            <div className={this.tabCssClass("allExercises")} onClick={LayoutActions.setActiveExercisesTab.bind(this, "allExercises")}>
              {i18n.t("allExercises")}
            </div>
            <div className={this.tabCssClass("exercisesNotAnswered")} onClick={LayoutActions.setActiveExercisesTab.bind(this, "exercisesNotAnswered")}>
              <i className="far fa-circle" />
            </div>
            <div className={this.tabCssClass("exercisesMarked")} onClick={LayoutActions.setActiveExercisesTab.bind(this, "exercisesMarked")}>
              <i className="fas fa-flag"></i>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <div className="session-sidebar">
        <div className="session-sidebar-top">{this.sidebarTop()}</div>
        <div className="session-sidebar-bottom" id="session-sidebar-bottom">
          {this.activeTab()}
        </div>
        <div className="session-sidebar-copyright">
          &copy; {i18n.t("copyright")} {new Date().getFullYear()}
        </div>
      </div>
    );
  }
}

export default Sidebar;
