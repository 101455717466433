import Actions from "../constants/actions";
import Dispatcher from "../dispatcher/dispatcher";

const LayoutActions = {
  toggleSidebar() {
    Dispatcher.dispatch({
      type: Actions.TOGGLE_SIDEBAR,
    });
  },
  toggleGradeModal() {
    Dispatcher.dispatch({
      type: Actions.TOGGLE_GRADE_MODAL,
    });
  },
  toggleTimeoutModal() {
    Dispatcher.dispatch({
      type: Actions.TOGGLE_TIMEOUT_MODAL,
    });
  },
  toggleCommitModal() {
    Dispatcher.dispatch({
      type: Actions.TOGGLE_COMMIT_MODAL,
    });
  },
  toggleResultModal() {
    Dispatcher.dispatch({
      type: Actions.TOGGLE_RESULT_MODAL,
    });
  },
  toggleResultDetails() {
    Dispatcher.dispatch({
      type: Actions.TOGGLE_RESULT_DETAILS,
    });
  },
  setActiveExercisesTab(exercisesTab) {
    Dispatcher.dispatch({
      type: Actions.SET_ACTIVE_EXERCISES_TAB,
      exercisesTab,
    });
  },
};

export default LayoutActions;
