import React from "react";
import Exercise from "./exercise";
import i18n from "../../utils/i18n";

class ExercisesMarked extends React.PureComponent {
  exercises() {
    let markedSessionExercises = this.props.store.session.session_exercises.filter((sessionExercise) => {
      return sessionExercise.get("marked") && sessionExercise.get("typology") == "exe";
    });

    let markedExerciseIds = markedSessionExercises
      .map((sessionExercise) => {
        return sessionExercise.get("exercise_id");
      })
      .toArray();

    let exercises = [];
    this.props.store.verification.exercise_groups.forEach((exerciseGroup) => {
      let markedExercises = exerciseGroup.get("exercises").filter((exercise) => {
        return markedExerciseIds.includes(exercise.get("id"));
      });

      markedExercises.forEach((exercise) => {
        exercises.push(<Exercise key={exercise.get("id")} store={this.props.store} exercise={exercise} />);
      });
    });

    if (exercises.length > 0) {
      return exercises;
    } else {
      return <div className="text-center font-italic mt-3">{i18n.t("noExercisesMarked")}</div>;
    }
  }

  render() {
    return <React.Fragment>{this.exercises()}</React.Fragment>;
  }
}

export default ExercisesMarked;
