const wrapperClass = ".modal-wrapper";
const wrapper = $(wrapperClass);

const showIfPresent = function () {
  if ($(wrapper).length > 0 && $(wrapper).html().length > 0) {
    $(wrapper).find(".modal").modal("show");
  }
};

const show = function (content) {
  hide();
  if (content !== undefined && content !== "" && content !== null) {
    $(wrapper).html(content);
  }
  $(wrapper).find(".modal").modal("show");
};

const hide = function () {
  $(wrapper).find(".modal").modal("hide");
  ReactRailsUJS.unmountComponents(wrapper);
  $(wrapper).html(null);
};

const reset = function () {
  hide();
  location.reload();
};

const redirect = function (url) {
  hide();
  window.location.replace(url);
};

export default {
  wrapperClass: wrapperClass,
  wrapper: wrapper,
  show: show,
  hide: hide,
  reset: reset,
  redirect: redirect,
  showIfPresent: showIfPresent,
};
