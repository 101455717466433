import React from "react";
import ClassNames from "classnames";
import i18n from "../../utils/i18n";

class Counters extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: true,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  counter(type) {
    return (
      <div className="sidebar-counter d-flex">
        <div className="col">{i18n.t(type)}</div>
        <div className="counter-value col-auto">
          {this.props.store.counters.get(type)} / {this.props.store.counters.exercisesCount}
        </div>
      </div>
    );
  }

  render() {
    let tabContentClass = ClassNames("sidebar-collapse-content", {
      "d-none": !this.state.expanded,
    });

    let iconClass = ClassNames("fas expanded-icon", {
      "fa-chevron-down": this.state.expanded,
      "fa-chevron-right": !this.state.expanded,
    });

    return (
      <div className="sidebar-collapse">
        <div className="sidebar-collapse-header" onClick={this.toggleExpanded}>
          <span>{i18n.t("status")}</span>
          <i className={iconClass} />
        </div>
        <div className={tabContentClass}>
          {this.counter("exercisesViewed")}
          {this.counter("exercisesAnswered")}
          {this.counter("exercisesNotAnswered")}
          {this.counter("exercisesMarked")}
        </div>
      </div>
    );
  }
}

export default Counters;
