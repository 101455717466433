import React from "react";

class Progress extends React.PureComponent {
  progress() {
    return this.completionPercentage();
  }

  progressCss() {
    return {
      width: this.completionPercentage(),
    };
  }

  completionPercentage() {
    return this.props.store.completionPercentage + "%";
  }

  render() {
    return (
      <div className="col-auto mobile-hide">
        <div className="session-progress">
          <div className="progress">
            <div className="progress-bar" style={this.progressCss()} />
          </div>
          <span>{this.progress()}</span>
        </div>
      </div>
    );
  }
}

export default Progress;
