import React, { useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import Axios from "axios";
import { Italian } from "flatpickr/dist/l10n/it";

import GroupSelect from "./GroupSelect";
import ClassroomSelect from "./ClassroomSelect";
import CategorySelect from "./CategorySelect";
import FormError from "./FormError";

const stepFields = [
  ["group_id", "user_ids", "classroom", "new_group_name", "new_group_auto_accept"],
  ["title", "start_day", "due_day", "test_time", "description", "category", "shuffle_exercises"],
];

function NewAssignmentForm(props) {
  const { fieldNames, fieldValues, i18n, verificationsPath, modal = true, apiToken, verificationId, allowExerciseShuffle } = props;
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [errors, setErrorState] = useState();
  const [classroomTaken, setClassroomTaken] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [form, setForm] = useState({
    [fieldNames.groupId]: fieldValues.groupId || "",
    [fieldNames.userIds]: Array.isArray(fieldValues.userIds) ? fieldValues.userIds : [],
    [fieldNames.classroom]: fieldValues.classroom || "",
    [fieldNames.newGroupName]: fieldValues.newGroupName || "",
    [fieldNames.newGroupAutoAccept]: fieldValues.newGroupAutoAccept,
    [fieldNames.title]: fieldValues.title || "",
    [fieldNames.startDay]: fieldValues.startDay || "",
    [fieldNames.dueDay]: fieldValues.dueDay || "",
    [fieldNames.testTime]: fieldValues.testTime || "",
    [fieldNames.description]: fieldValues.description || "",
    [fieldNames.category]: fieldValues.category || "",
    [fieldNames.shuffleExercises]: allowExerciseShuffle ? fieldValues.shuffleExercises : false,
  });

  const backButtonProps =
    step === 1
      ? {
          "data-dismiss": "modal",
        }
      : {};

  const checkErrors = async () => {
    try {
      const data = Object.entries(form).reduce((carry, [key, item]) => {
        const match = Array.from(key.matchAll(/[\w_]+/g));
        if (item) {
          carry[match[1][0]] = item;
        }
        return carry;
      }, {});

      const response = await Axios.put(
        `/api/verifications/${verificationId}/check`,
        {
          new_assignment_form: data,
          locale: i18n.locale,
        },
        {
          headers: { Authorization: apiToken },
        }
      );

      return response.data;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formElement = document.getElementById("new_new_assignment_form");

    setClassroomTaken(false);
    if (step === 1 && !form[fieldNames.groupId] && !form[fieldNames.classroom] && !form[fieldNames.newGroupName]) {
      setError(i18n.errors.selectGroup);
      return;
    }

    const errorsResponse = await checkErrors();

    if (errorsResponse && !errorsResponse.valid && Object.keys(errorsResponse.errors).some((key) => stepFields[step - 1].includes(key))) {
      setErrorState(errorsResponse.errors);
      return;
    }

    setError("");
    if (step !== 2) {
      setStep((oldStep) => oldStep + 1);
      return;
    }

    Rails.fire(formElement, "submit");
  };

  const handleBack = (e) => {
    setError("");
    if (step !== 1) {
      e.preventDefault();
      e.stopPropagation();
      setStep((oldStep) => oldStep - 1);
    }
  };

  const handleInputChange = (inputName) => (e) => {
    setForm((oldForm) => ({
      ...oldForm,
      [inputName]: e.target.type === "checkbox" ? (e.target.checked ? "1" : "") : e.target.value,
    }));
  };

  const handleClassroomChange = (classroom) => {
    const otherGroupFields = classroom
      ? {
          [fieldNames.newGroupName]: "",
          [fieldNames.newGroupAutoAccept]: "",
          [fieldNames.groupId]: "",
          [fieldNames.userIds]: [],
        }
      : {};

    setForm((oldForm) => ({
      ...oldForm,
      [fieldNames.classroom]: classroom.id,
      ...otherGroupFields,
    }));
    setGroupName(classroom.name);
  };

  const handleGroupChange = (group) => {
    const otherGroupFields =
      group.groupId || group.userIds.length > 0
        ? {
            [fieldNames.classroom]: "",
            [fieldNames.newGroupName]: "",
            [fieldNames.newGroupAutoAccept]: "",
          }
        : {};

    setForm((oldForm) => ({
      ...oldForm,
      [fieldNames.groupId]: group.groupId,
      [fieldNames.userIds]: group.userIds,
      ...otherGroupFields,
    }));
    setGroupName(group.groupName);
  };

  const handleNewGroupNameChange = (e) => {
    const otherGroupFields = e.target.value
      ? {
          [fieldNames.classroom]: "",
          [fieldNames.groupId]: "",
          [fieldNames.userIds]: [],
        }
      : {};

    setForm((oldForm) => ({
      ...oldForm,
      [fieldNames.newGroupName]: e.target.value,
      ...otherGroupFields,
    }));
    setGroupName(e.target.value);
  };

  const handleValueChange =
    (inputName, transform = (value) => value) =>
    (value) => {
      setForm((oldForm) => ({
        ...oldForm,
        [inputName]: transform(value),
      }));
    };

  const handleDateChange = (inputName) => (_dates, dateStr) => {
    setForm((oldForm) => ({
      ...oldForm,
      [inputName]: dateStr,
    }));
  };

  return (
    <>
      <div className={modal ? "modal-body" : ""}>
        {error && (
          <div className="alert alert-danger mb-4" role="alert">
            {error}
          </div>
        )}

        <div className="steps">
          <div id="new-assignment-modal" className="step-1" style={{ display: step === 1 ? "block" : "none" }}>
            <div className={`card ${errors?.group_id?.length > 0 ? "card-error" : ""}`}>
              <div id="existing-group" className="card-header" data-toggle="collapse" data-target="#existing-group-collapse" aria-expanded="false" aria-controls="existing-group-collapse">
                <h5 className="mb-0">
                  <button className="btn btn-link">{i18n.selectExistingGroup}</button>
                </h5>
              </div>
              <div id="existing-group-collapse" className="collapse" aria-labelledby="existing-group" data-parent="#new-assignment-modal">
                <div className="card-body">
                  <p className="text-secondary small mb-4">{i18n.selectExistingGroupDescription}</p>
                  <GroupSelect {...props} groupId={form[fieldNames.groupId]} userIds={form[fieldNames.userIds]} onChange={handleGroupChange} />
                </div>
              </div>
            </div>
            <FormError errors={errors} fieldName="group_id" />

            <div className={`card mt-4 ${errors?.new_group_name?.length > 0 ? "card-error" : ""}`}>
              <div id="new-group" className="card-header" data-toggle="collapse" data-target="#new-group-collapse" aria-expanded="false" aria-controls="new-group-collapse">
                <h5 className="mb-0">
                  <button className="btn btn-link">{i18n.newGroup}</button>
                </h5>
              </div>
              <div id="new-group-collapse" className="collapse" aria-labelledby="new-group" data-parent="#new-assignment-modal">
                <div className="card-body">
                  <p className="text-secondary small mb-4">{i18n.newGroupDescription}</p>
                  <div className="form-group">
                    <label className="form-control-label" htmlFor="new_group_name">
                      {i18n.newGroupName}
                    </label>
                    <input type="text" className="form-control" id="new_group_name" name={fieldNames.newGroupName} onChange={handleNewGroupNameChange} />
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="new_group_auto_accept"
                      value="1"
                      name={fieldNames.newGroupAutoAccept}
                      onChange={handleInputChange(fieldNames.newGroupAutoAccept)}
                    />
                    <label htmlFor="new_group_auto_accept" className="form-check-label">
                      {i18n.newGroupAutoaccept}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <FormError errors={errors} fieldName="new_group_name" />

            <div className={`card mt-4 ${classroomTaken || errors?.classroom?.length > 0 ? "card-error" : ""}`}>
              <div
                id="new-group-from-classroom"
                className="card-header"
                data-toggle="collapse"
                data-target="#new-group-from-classroom-collapse"
                aria-expanded="false"
                aria-controls="new-group-from-classroom-collapse"
              >
                <h5 className="mb-0">
                  <button className="btn btn-link">{i18n.newGroupFromClassroom}</button>
                </h5>
              </div>
              <div id="new-group-from-classroom-collapse" className="collapse" aria-labelledby="new-group-from-classroom" data-parent="#new-assignment-modal">
                <div className="card-body">
                  <p className="text-secondary small mb-4">{i18n.newGroupFromClassroomDescription}</p>
                  <ClassroomSelect {...props} className="p-0 bg-transparent" selectedClassroom={form[fieldNames.classroom]} onClassroomChange={handleClassroomChange} />
                </div>
              </div>
            </div>
          </div>
          <FormError errors={errors} fieldName="classroom" />
          {classroomTaken && <small className="form-text text-error">{i18n.errors.classroomTaken}</small>}

          <div className="step-2" style={{ display: step === 2 ? "block" : "none" }}>
            <div className="form-group">
              <label htmlFor="new_assignment_form_assigned_to">{i18n.fields.assignedTo}</label>
              <input type="text" id="new_assignment_form_assigned_to" className="form-control" name="assigned_to" value={form[fieldNames.newGroupName] || groupName} readOnly />
            </div>

            <div className="form-group">
              <label htmlFor="new_assignment_form_title">{i18n.fields.title}</label>
              <input
                type="text"
                id="new_assignment_form_title"
                className="form-control"
                name={fieldNames.title}
                value={form[fieldNames.title]}
                onChange={handleInputChange(fieldNames.title)}
                required
              />
              <FormError errors={errors} fieldName="title" />
            </div>

            <div className="row">
              <div className={modal ? "col-lg-3 pr-0" : "col-6"}>
                <div className="form-group">
                  <label htmlFor="new_assignment_form_start_day">{i18n.fields.startDay}</label>
                  <Flatpickr
                    value={form[fieldNames.startDay]}
                    onChange={handleDateChange(fieldNames.startDay)}
                    className="form-control bg-white"
                    options={{
                      dateFormat: "d-m-Y",
                      position: "below",
                      locale: Italian,
                    }}
                    name={fieldNames.startDay}
                    id="new_assignment_form_start_day"
                  />
                  <FormError errors={errors} fieldName="start_day" />
                </div>
              </div>
              {modal && <div className="col-lg-1 date-input-separator">/</div>}
              <div className={modal ? "col-lg-3 p-0" : "col-6"}>
                <div className="form-group">
                  <label htmlFor="new_assignment_form_due_day">{i18n.fields.dueDay}</label>
                  <Flatpickr
                    value={form[fieldNames.dueDay]}
                    onChange={handleDateChange(fieldNames.dueDay)}
                    className="form-control bg-white"
                    options={{
                      dateFormat: "d-m-Y",
                      position: "below",
                      locale: Italian,
                    }}
                    name={fieldNames.dueDay}
                    id="new_assignment_form_due_day"
                  />
                  <FormError errors={errors} fieldName="due_day" />
                </div>
              </div>
              <div className={modal ? "col-lg-5" : "col-12"}>
                <div className="form-group">
                  <label htmlFor="new_assignment_form_test_time">{i18n.fields.testTime}</label>
                  <Flatpickr
                    value={form[fieldNames.testTime]}
                    onChange={handleDateChange(fieldNames.testTime)}
                    className="form-control bg-white"
                    options={{
                      dateFormat: "H:i",
                      enableTime: true,
                      noCalendar: true,
                      allowInput: false,
                      time_24hr: true,
                      defaultHour: 1,
                      defaultMinute: 0,
                      minuteIncrement: 10,
                      locale: Italian,
                    }}
                    name={fieldNames.testTime}
                    id="new_assignment_form_due_day"
                  />
                  <FormError errors={errors} fieldName="test_time" />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="new_assignment_form_description">{i18n.fields.description}</label>
              <textarea
                name={fieldNames.description}
                id="new_assignment_form_description"
                className="form-control"
                rows="4"
                value={form[fieldNames.description]}
                onChange={handleInputChange(fieldNames.description)}
              />
              <FormError errors={errors} fieldName="description" />
            </div>

            <CategorySelect {...props.categoryOptions} fieldName={fieldNames.category} selectedOption={form[fieldNames.category]} onChange={handleValueChange(fieldNames.category)} />
            <FormError errors={errors} fieldName="category" />

            {allowExerciseShuffle && (
              <div className="form-check mt-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="new_assignment_form_shuffle_exercises"
                  value="1"
                  name={fieldNames.shuffleExercises}
                  onChange={handleInputChange(fieldNames.shuffleExercises)}
                />
                <label htmlFor="new_assignment_form_shuffle_exercises" className="form-check-label">
                  {i18n.fields.shuffleExercises}
                </label>
                <FormError errors={errors} fieldName="shuffle_exercises" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={modal ? "modal-footer" : "d-flex mt-4"}>
        {modal || step !== 1 ? (
          <div className="btn btn-lg btn-outline-secondary mr-auto" {...backButtonProps} onClick={handleBack}>
            {step === 1 ? i18n.buttons.cancel : i18n.buttons.back}
          </div>
        ) : (
          <a href={verificationsPath} className="btn btn-lg btn-outline-secondary mr-auto">
            {i18n.buttons.cancel}
          </a>
        )}

        <input type="submit" name="commit" value={step === 2 ? i18n.buttons.assign : i18n.buttons.continue} className="btn btn-primary btn btn-lg btn-primary" onClick={handleSubmit} />
      </div>
    </>
  );
}

export default NewAssignmentForm;
